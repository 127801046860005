import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "src/components/ui/dialog";
import { Button } from "src/components/ui/button";
import { BookmarkCheck, ChevronRight } from "lucide-react";
import { motion } from "framer-motion";

const EmailConfirmationDialog = ({ isOpen, onClose, email }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-md border-4 border-black bg-white shadow-[8px_8px_0_0_#000] p-0">
        <div className="p-6">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: "spring", duration: 0.5 }}
            className="w-20 h-20 mx-auto mb-6 rounded-full bg-[#f8bf5d] flex items-center justify-center border-4 border-black"
          >
            <BookmarkCheck className="w-10 h-10 text-black" />
          </motion.div>

          <DialogHeader className="text-center mb-4">
            <DialogTitle className="text-2xl font-bold">
              Progress Saved!
            </DialogTitle>
            <DialogDescription className="text-lg mt-2">
              Want to continue later? We've sent a link to:
              <div className="font-bold text-black mt-1">{email}</div>
            </DialogDescription>
          </DialogHeader>

          <div className="space-y-4 mt-6">
            <p className="text-gray-600 text-center">
              You can use this link to return to your form whenever you're ready
              to continue. Your progress will be saved automatically.
            </p>

            <div className="space-y-2">
              <Button
                onClick={onClose}
                className="w-full bg-[#f8bf5d] hover:bg-[#fba40d] text-black border-4 border-black font-bold shadow-[4px_4px_0_0_#000] transition-all duration-200 hover:translate-y-1 hover:shadow-[2px_2px_0_0_#000]"
              >
                Continue Now
                <ChevronRight className="ml-2 h-5 w-5" />
              </Button>

              <Button
                onClick={() => window.close()}
                variant="outline"
                className="w-full border-4 border-black text-black hover:bg-gray-50"
              >
                Finish Later
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EmailConfirmationDialog;
