import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Alert, AlertDescription } from "./ui/alert";
import axios from "axios";
import { createTrelloCard } from "./trelloIntegration";
import { Textarea } from "./ui/textarea";
import PostDetails from "./PostDetails";

import {
  Lock,
  Unlock,
  Loader2,
  Search,
  ExternalLink,
  ChevronDown,
  ChevronUp,
  Download,
  RefreshCw,
  Plus,
  Check,
  ArrowLeft,
} from "lucide-react";
import { createClient } from "@supabase/supabase-js";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { ScrollArea } from "./ui/scroll-area";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Badge } from "./ui/badge";
import OpenAI from "openai";

const API_KEY =
  "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";

const openai = new OpenAI({
  apiKey: API_KEY,
  dangerouslyAllowBrowser: true,
});

const supabase = createClient(
  "https://jhknbuxutiqdvlxnvawv.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Impoa25idXh1dGlxZHZseG52YXd2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjcyOTgxMDYsImV4cCI6MjA0Mjg3NDEwNn0.l3K1xIyN2uZHUrXvjSGr_UXW2s2n9dQvXz8kB_x4K1E"
);

const AdminPage = () => {
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState("");
  const [submissions, setSubmissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const contentIdeasRef = useRef(null);
  const selectedIdeasRef = useRef(null);
  const generatedPostsRef = useRef(null);
  const [sortConfig, setSortConfig] = useState({
    key: "created_at",
    direction: "desc",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [contentIdeas, setContentIdeas] = useState([]);
  const [isGeneratingIdeas, setIsGeneratingIdeas] = useState({});
  const [selectedIdeas, setSelectedIdeas] = useState([]);
  const [generatedPosts, setGeneratedPosts] = useState([]);
  const [isGeneratingPosts, setIsGeneratingPosts] = useState(false);
  const [isLoadingSubmission, setIsLoadingSubmission] = useState(false);
  const [isGeneratingIdeasInPopup, setIsGeneratingIdeasInPopup] =
    useState(false);
  const [isScrapingPosts, setIsScrapingPosts] = useState(false);
  const [view, setView] = useState("list");
  const [manualPost, setManualPost] = useState({
    title: "",
    hook: "",
    content: "",
    contentType: "Text Post",
  });

  useEffect(() => {
    const checkAuthStatus = () => {
      const authStatus = sessionStorage.getItem("isAdminAuthenticated");
      if (authStatus === "true") {
        setIsAuthenticated(true);
        fetchSubmissions();
      }
    };

    checkAuthStatus();
  }, []);

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === "michalskykombajn") {
      setIsAuthenticated(true);
      sessionStorage.setItem("isAdminAuthenticated", "true");
      setError("");
      fetchSubmissions();
    } else {
      setError("Incorrect password. Please try again.");
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    sessionStorage.removeItem("isAdminAuthenticated");
    setSubmissions([]);
    setView("list");
  };

  const fetchSubmissions = async () => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from("form_submissions")
        .select("*")
        .order("created_at", { ascending: false });

      if (error) throw error;
      setSubmissions(data);
    } catch (error) {
      console.error("Error fetching submissions:", error);
      setError("Failed to fetch submissions. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const scrapeLinkedInPosts = async (submission) => {
    setIsScrapingPosts(true);
    setError(null);

    // Validate submission has LinkedIn profile
    if (!submission.linkedin_profile) {
      throw new Error("No LinkedIn profile URL provided");
    }

    try {
      console.log(
        "Starting LinkedIn scraping for profile:",
        submission.linkedin_profile
      );

      const response = await axios.post(
        "https://api.apify.com/v2/actor-tasks/combajn~linkedin-post-scraper-task/run-sync-get-dataset-items?token=apify_api_s9HFDzKOJD1opnaSeq0UEPsSMnzhYV3VZica",
        {
          urls: [submission.linkedin_profile],
          limitPerSource: 5,
          deepScrape: false,
          maxDelay: 10,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("API Response:", response.data);

      // Handle API error response
      if (response.data && response.data.error) {
        console.error("API returned error:", response.data.error);
        throw new Error(response.data.error.message || "API returned an error");
      }

      // Validate response data is an array
      if (!Array.isArray(response.data)) {
        console.error("Invalid response format:", response.data);
        throw new Error("Invalid API response format");
      }

      // Process the scraped posts
      const scrapedPosts = response.data
        .slice(0, 5) // Ensure we only take up to 5 posts
        .map((post, index) => {
          // Validate and clean each post data
          const cleanedPost = {
            text: post.text || "",
            url: post.url || "",
            postedAt: post.postedAtISO || new Date().toISOString(),
            numLikes: post.numLikes || 0,
            numComments: post.numComments || 0,
          };

          console.log(`Processed post ${index + 1}:`, cleanedPost);
          return cleanedPost;
        });

      // Ensure we always have 5 posts by padding with empty posts if needed
      const normalizedPosts = [...scrapedPosts];
      while (normalizedPosts.length < 5) {
        normalizedPosts.push({
          text: "",
          url: "",
          postedAt: new Date().toISOString(),
          numLikes: 0,
          numComments: 0,
        });
      }

      // Prepare the update object for the database
      const updateObject = {};
      normalizedPosts.forEach((post, index) => {
        updateObject[`last_post_${index + 1}`] = JSON.stringify(post);
      });

      // Update the submission in the database
      try {
        const { data, error } = await supabase
          .from("form_submissions")
          .update(updateObject)
          .eq("id", submission.id);

        if (error) {
          console.error("Error updating submission in database:", error);
          throw new Error("Failed to update posts in database");
        }

        console.log("Successfully updated posts in database");

        // Update the selectedSubmission state with the scraped posts
        setSelectedSubmission((prev) => ({
          ...prev,
          ...updateObject,
        }));

        // Return the processed posts
        return normalizedPosts.map((post) => post.text);
      } catch (dbError) {
        console.error("Database error:", dbError);
        throw new Error("Failed to save scraped posts to database");
      }
    } catch (error) {
      console.error("Error in scrapeLinkedInPosts:", error);

      // Handle different types of errors
      if (axios.isAxiosError(error)) {
        // Handle Axios specific errors
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const apiError =
            error.response.data?.error?.message ||
            error.response.data?.message ||
            error.message;
          throw new Error(apiError);
        } else if (error.request) {
          // The request was made but no response was received
          throw new Error("No response received from API. Please try again.");
        } else {
          // Something happened in setting up the request
          throw new Error("Failed to make API request. Please try again.");
        }
      }

      // For non-Axios errors, rethrow with the original message
      throw new Error(error.message || "Failed to scrape LinkedIn posts");
    } finally {
      setIsScrapingPosts(false);
      console.log("LinkedIn scraping process completed");
    }
  };

  const openDetailView = async (submission) => {
    setIsLoadingSubmission(true);
    try {
      const { data, error } = await supabase
        .from("form_submissions")
        .select(
          `
          *,
          interview_questions,
          interview_answers
        `
        )
        .eq("id", submission.id)
        .single();

      if (error) throw error;

      setSelectedSubmission(data);
      setContentIdeas(JSON.parse(data.content_ideas || "[]"));
      setSelectedIdeas(JSON.parse(data.selected_ideas || "[]"));
      setGeneratedPosts(JSON.parse(data.full_posts || "[]"));
      setView("detail");
    } catch (error) {
      console.error("Error fetching submission details:", error);
      setError("Failed to fetch submission details. Please try again.");
    } finally {
      setIsLoadingSubmission(false);
    }
  };

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const openTrelloCard = () => {
    if (selectedSubmission.trello_card_id) {
      // Trello card URL format
      const trelloCardUrl = `https://trello.com/c/${selectedSubmission.trello_card_id}`;
      window.open(trelloCardUrl, "_blank");
    } else {
      setError("Trello card ID is not available for this submission.");
    }
  };

  const sortedSubmissions = useCallback(() => {
    const sortableSubmissions = [...submissions];
    if (sortConfig.key) {
      sortableSubmissions.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableSubmissions;
  }, [submissions, sortConfig]);

  const filteredSubmissions = sortedSubmissions().filter(
    (submission) =>
      submission.email?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      submission.website?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      submission.industry?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const paginatedSubmissions = filteredSubmissions.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredSubmissions.length / itemsPerPage);

  const exportToCSV = () => {
    const headers = Object.keys(submissions[0]).join(",");
    const csv = [
      headers,
      ...submissions.map((submission) =>
        Object.values(submission)
          .map((value) =>
            typeof value === "string" ? `"${value.replace(/"/g, '""')}"` : value
          )
          .join(",")
      ),
    ].join("\n");

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "submissions.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const generateContentIdeas = async (submission) => {
    setIsGeneratingIdeasInPopup(true);
    setIsGeneratingIdeas((prev) => ({ ...prev, [submission.id]: true }));
    setError(null);
    setSelectedSubmission(submission);
    try {
      // Format interview answers for the prompt
      const interviewAnswers = submission.interview_answers || [];
      const formattedAnswers = interviewAnswers
        .map((answer, index) => {
          const questions = [
            "Who are you, and what’s your superpower? Introduce yourself.",
            "What is [Company Name]? How do you differentiate yourself from competitors?",
            "What are the top three values you live by, and how do they reflect in your business? Can you explain how these values are embodied in your work?",
            "Can you share a success story about yourself or a customer? How has your product or service positively impacted a customer or their business?",
            "What is your most controversial opinion about the industry?",
            "Do you remember the last piece of feedback you received from a client? What was it, and who shared it?",
            "What trends or shifts are you noticing in the industry? How do you think these will affect your customers, and how is your business adapting to them?",
            "Do you have any controversial opinions on your industry or its trends?",
            "Is there a topic you’d like to discuss now? If so, please share it with us.",
          ];
          return `Q: ${questions[index]}\nA: ${answer}`;
        })
        .join("\n\n");
      const systemMessage =
        "You are a content strategy AI assistant specializing in creating engaging, industry-specific content ideas. Your task is to generate content ideas that precisely match the user's writing style and preferences based on their previous posts, while creating entirely new topics that are highly relevant to their specific industry and current trends. Each idea should include an attention-grabbing hook.";

      const userMessage = `Generate 12 content ideas with hooks that match the user's style and are highly specific to their industry and business. Consider the following information:
  
      Industry: ${submission.industry}
      USP + Product: ${submission.usp}
      ICP + Personas: ${submission.icp}
      Topics to Talk About: ${submission.topics_to_talk_about}

          Interview Insights:
    ${formattedAnswers}
  
      Focus on creating content ideas that align with the user's specified "Topics to Talk About" while also considering interview and their industry, USP, and target audience.`;

      const response = await openai.chat.completions.create({
        model: "gpt-4o-2024-08-06",
        messages: [
          { role: "system", content: systemMessage },
          { role: "user", content: userMessage },
        ],
        functions: [
          {
            name: "generate_content_ideas",
            description: "Generate content ideas for posts",
            parameters: {
              type: "object",
              properties: {
                ideas: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      title: {
                        type: "string",
                        description:
                          "A catchy, industry-specific title (max 10 words)",
                      },
                      description: {
                        type: "string",
                        description:
                          "A brief, compelling description of the content (max 30 words)",
                      },
                      hook: {
                        type: "string",
                        description:
                          "An attention-grabbing opening line that would start the post (max 20 words)",
                      },
                    },
                    required: ["title", "description", "contentType", "hook"],
                  },
                },
              },
              required: ["ideas"],
            },
          },
        ],
        function_call: { name: "generate_content_ideas" },
      });

      const functionCall = response.choices[0].message.function_call;
      const generatedIdeas = JSON.parse(functionCall.arguments).ideas;

      const validatedIdeas = generatedIdeas.map((idea, index) => ({
        id: `${submission.id}-${index}`,
        title: (idea.title || `Content Idea ${index + 1}`).slice(0, 100),
        description: (idea.description || "No description provided").slice(
          0,
          200
        ),
        contentType: "Text Post",
        hook: idea.hook || "",
      }));

      setContentIdeas(validatedIdeas);

      // Update the submission in the database with the new content ideas
      const { data, error } = await supabase
        .from("form_submissions")
        .update({ content_ideas: JSON.stringify(validatedIdeas) })
        .eq("id", submission.id);

      if (error) throw error;

      // Update the selectedSubmission state with the new content ideas
      setSelectedSubmission((prev) => ({
        ...prev,
        content_ideas: JSON.stringify(validatedIdeas),
      }));

      // Scroll to the content ideas section
      setTimeout(() => scrollToRef(contentIdeasRef), 100);
    } catch (error) {
      console.error("Error generating content ideas:", error);
      setError(
        `Failed to generate content ideas: ${error.message}. Please try again.`
      );
    } finally {
      setIsGeneratingIdeasInPopup(false);
    }
  };

  const generateFullPost = async (idea, formData, linkedInPosts) => {
    const linkedInPostsPrompt =
      linkedInPosts.length > 0
        ? `Analyze the following posts ONLY to understand the user's writing style, tone of voice, and language preferences:
  
      ${linkedInPosts.map((post, index) => `${index + 1}. ${post}`).join("\n")}
  
      It's crucial that you closely mimic this style in the post you generate, especially in the opening hook. Pay attention to:
      - The level of formality or casualness in the language
      - Any specific phrases or expressions the user tends to use
      - The overall structure and flow of their posts
      - max 1000 characters
      - The general tone (e.g., professional, friendly, authoritative)
      - The use of emojis (if present, incorporate them in a similar manner; if not, avoid using them)
  
      DO NOT use the topics or content from these posts for generating new content. Use them ONLY for understanding the user's writing style.
  
      Based on this analysis of style (NOT content), `
        : "";

    // Handle interview answers from JSONB
    let interviewAnswers = [];
    if (formData.interview_answers) {
      // Since it's JSONB, it should already be parsed into a JavaScript object
      interviewAnswers = Object.entries(formData.interview_answers);
    }

    // Create a prompt section for interview answers
    const interviewPrompt =
      interviewAnswers.length > 0
        ? `Consider the following interview answers when crafting the post:
        ${interviewAnswers
          .map(
            ([question, answer], index) =>
              `Question ${index + 1}: ${question}\nAnswer: ${answer}`
          )
          .join("\n\n")}
        
        Use these insights to add depth and personal touch to the content.`
        : "";

    const prompt = `${linkedInPostsPrompt}Generate a full post(STRICT 1000 character limit) for the following idea:
  
        Title: ${idea.title}
        Description: ${idea.description}
        Content Type: Text Post
  
        ${interviewPrompt}
  
        Create a full post (max 3000 characters) that ${
          linkedInPosts.length > 0
            ? "closely mimics the user's writing style, tone of voice, and language preferences, including the use of emojis if that's part of their style"
            : "is suitable for professional content, maintaining a consistent tone"
        }.
  
        CRUCIALLY, start the post with an attention-grabbing hook. This hook MUST:
        1. Be unexpected, provocative, or intriguing
        2. Use techniques like posing a surprising question, sharing a shocking statistic, or making a bold statement
        3. Potentially use emojis if that fits the user's style
        4. Be designed to make the reader stop scrolling and want to read more
        5. Be no more than 1-2 short sentences
  
        After the hook, the post should flow naturally into the main content, expanding on the idea while maintaining the user's typical style and structure.
  
        Return the result as a JSON object containing 'fullPost' and 'hook' fields.
  
        Remember:
        - The post MUST start with a short, attention-grabbing hook(MAX 25 characters)
        - Match the user's style of emoji usage (if any)
        - Maintain the user's typical paragraph structure and length
        - Use language and expressions similar to the user's style
        - Keep the content relevant to the provided idea and current industry trends
        - Incorporate insights from the interview answers if available
        - DO NOT use topics or content from the user's previous posts`;

    try {
      const response = await openai.chat.completions.create({
        model: "gpt-4o-2024-08-06",
        messages: [
          {
            role: "system",
            content:
              "You are a content creator specializing in crafting engaging posts with attention-grabbing hooks. Your task is to generate a full post for the given content idea, matching the user's writing style and preferences. The post MUST start with a compelling hook that immediately captures attention.",
          },
          {
            role: "user",
            content: prompt,
          },
        ],
        functions: [
          {
            name: "generate_full_post",
            description: "Generate a full post based on the given idea",
            parameters: {
              type: "object",
              properties: {
                fullPost: {
                  type: "string",
                  description:
                    "The full post content, including the hook and main body (max 3000 characters)",
                },
                hook: {
                  type: "string",
                  description:
                    "The attention-grabbing opening line or lines (max 50 characters)",
                },
              },
              required: ["fullPost", "hook"],
            },
          },
        ],
        function_call: { name: "generate_full_post" },
      });

      const functionCall = response.choices[0].message.function_call;
      const generatedPost = JSON.parse(functionCall.arguments);

      return {
        ...idea,
        fullPost: generatedPost.fullPost.slice(0, 3000),
        hook: generatedPost.hook.slice(0, 50),
      };
    } catch (error) {
      console.error(`Error generating post for idea "${idea.title}":`, error);
      throw error;
    }
  };

  const generateFullPosts = async () => {
    if (!selectedSubmission) {
      setError("No submission selected. Please select a submission first.");
      return;
    }

    setIsGeneratingPosts(true);
    setError(null);
    const fullPosts = [];

    try {
      const linkedInPosts = [
        selectedSubmission.last_post_1,
        selectedSubmission.last_post_2,
        selectedSubmission.last_post_3,
        selectedSubmission.last_post_4,
        selectedSubmission.last_post_5,
      ]
        .filter(Boolean)
        .map((post) => JSON.parse(post).text);

      const todoListId = selectedSubmission.trello_todo_list_id;
      const boardId = selectedSubmission.trello_board_id;
      if (!todoListId || !boardId) {
        throw new Error(
          "Trello TODO list ID or Board ID not found for this submission"
        );
      }

      for (const idea of selectedIdeas) {
        try {
          const fullPost = await generateFullPost(
            idea,
            selectedSubmission,
            linkedInPosts
          );

          console.log("Full post generated:", fullPost);

          // Create Trello card for the generated post
          try {
            const trelloCardData = {
              name: fullPost.title,
              desc: fullPost.fullPost,
              idList: todoListId,
            };
            console.log("Creating Trello card with data:", trelloCardData);
            const trelloCard = await createTrelloCard(trelloCardData);
            console.log("Trello card created:", trelloCard);

            fullPost.trelloCardUrl = trelloCard.shortUrl;
          } catch (trelloError) {
            console.error("Failed to create Trello card:", trelloError);
            fullPost.trelloCardError = "Failed to create Trello card";
          }

          fullPosts.push(fullPost);
        } catch (error) {
          console.error(
            `Failed to generate post for idea "${idea.title}":`,
            error
          );
          fullPosts.push({ ...idea, error: error.message });
        }
      }

      if (fullPosts.length === 0) {
        throw new Error("No posts were successfully generated");
      }

      setGeneratedPosts(fullPosts);

      // Update the submission in the database with the new full posts
      const { data, error } = await supabase
        .from("form_submissions")
        .update({ full_posts: JSON.stringify(fullPosts) })
        .eq("id", selectedSubmission.id);

      if (error) throw error;

      // Update the selectedSubmission state with the new full posts
      setSelectedSubmission((prev) => ({
        ...prev,
        full_posts: JSON.stringify(fullPosts),
      }));

      // Scroll to the generated posts section
      setTimeout(() => scrollToRef(generatedPostsRef), 100);
    } catch (error) {
      console.error("Error in generateFullPosts:", error);
      setError(`Failed to generate full posts: ${error.message}`);
    } finally {
      setIsGeneratingPosts(false);
    }
  };

  const handleIdeaSelection = (idea) => {
    setSelectedIdeas((prev) => {
      const newSelectedIdeas = prev.includes(idea)
        ? prev.filter((i) => i !== idea)
        : [...prev, idea];

      // Scroll to the selected ideas section if there are selected ideas
      if (newSelectedIdeas.length > 0) {
        setTimeout(() => scrollToRef(selectedIdeasRef), 100);
      }

      return newSelectedIdeas;
    });
  };

  const renderScrapedPosts = () => {
    const posts = [
      selectedSubmission.last_post_1,
      selectedSubmission.last_post_2,
      selectedSubmission.last_post_3,
      selectedSubmission.last_post_4,
      selectedSubmission.last_post_5,
    ]
      .filter(Boolean)
      .map((post) => {
        try {
          return JSON.parse(post);
        } catch (e) {
          console.error("Error parsing post:", e);
          return null;
        }
      })
      .filter(Boolean);

    if (posts.length === 0) {
      return <p>No scraped posts available.</p>;
    }

    return (
      <div className="space-y-4">
        {posts.map((post, index) => (
          <div key={index} className="bg-gray-100 p-4 rounded">
            <h4 className="font-semibold mb-2">Post {index + 1}</h4>
            <p className="text-sm text-gray-600 mb-2">{post.text}</p>
            <p className="text-xs text-gray-500">
              Posted on: {new Date(post.postedAt).toLocaleString()}
            </p>
            <p className="text-xs text-gray-500">
              Likes: {post.numLikes}, Comments: {post.numComments}
            </p>
            <a
              href={post.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline text-sm"
            >
              View on LinkedIn
            </a>
          </div>
        ))}
      </div>
    );
  };

  const renderListView = () => (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold">Form Submissions</h2>
        <Button
          onClick={exportToCSV}
          className="flex items-center hover:bg-blue-600"
        >
          <Download className="mr-2 h-4 w-4" />
          Export to CSV
        </Button>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center mb-4 space-y-2 md:space-y-0 md:space-x-2">
        <Input
          type="text"
          placeholder="Search by email, website, or industry"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="max-w-md"
          icon={<Search className="h-4 w-4 text-gray-500" />}
        />
        <Select
          value={itemsPerPage.toString()}
          onValueChange={(value) => setItemsPerPage(Number(value))}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Items per page" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="5">5 per page</SelectItem>
            <SelectItem value="10">10 per page</SelectItem>
            <SelectItem value="20">20 per page</SelectItem>
            <SelectItem value="50">50 per page</SelectItem>
          </SelectContent>
        </Select>
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Loader2 className="h-8 w-8 animate-spin" />
          <span className="ml-2">Loading submissions...</span>
        </div>
      ) : paginatedSubmissions.length > 0 ? (
        <div className="overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow>
                {["Website", "Email", "Industry", "Submitted At"].map(
                  (header) => (
                    <TableHead
                      key={header}
                      className="cursor-pointer hover:bg-gray-100"
                      onClick={() =>
                        handleSort(header?.toLowerCase().replace(" ", "_"))
                      }
                    >
                      <div className="flex items-center">
                        {header}
                        {sortConfig.key ===
                          header?.toLowerCase().replace(" ", "_") &&
                          (sortConfig.direction === "asc" ? (
                            <ChevronUp className="ml-1 h-4 w-4" />
                          ) : (
                            <ChevronDown className="ml-1 h-4 w-4" />
                          ))}
                      </div>
                    </TableHead>
                  )
                )}
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {paginatedSubmissions.map((submission) => (
                <TableRow key={submission.id} className="hover:bg-gray-50">
                  <TableCell className="font-medium">
                    {submission.website}
                  </TableCell>
                  <TableCell>{submission.email}</TableCell>
                  <TableCell>{submission.industry}</TableCell>
                  <TableCell>
                    {new Date(submission.created_at).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    <div className="flex space-x-2">
                      <Button
                        onClick={() => openDetailView(submission)}
                        variant="outline"
                        className="hover:bg-blue-50 transition-colors duration-200"
                      >
                        View Details
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        <p className="text-center text-gray-500 my-4">No submissions found.</p>
      )}

      {/* Pagination */}
      <div className="flex justify-between items-center mt-4">
        <p className="text-sm text-gray-500">
          Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
          {Math.min(currentPage * itemsPerPage, filteredSubmissions.length)} of{" "}
          {filteredSubmissions.length} entries
        </p>
        <div className="flex space-x-2">
          <Button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            variant="outline"
            className="hover:bg-gray-100"
          >
            Previous
          </Button>
          {[...Array(totalPages).keys()].map((number) => (
            <Button
              key={number}
              onClick={() => setCurrentPage(number + 1)}
              variant={currentPage === number + 1 ? "default" : "outline"}
              className={`hover:bg-gray-100 ${
                currentPage === number + 1 ? "bg-blue-500 text-white" : ""
              }`}
            >
              {number + 1}
            </Button>
          ))}
          <Button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
            variant="outline"
            className="hover:bg-gray-100"
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );

  const renderDetailView = () => {
    if (!selectedSubmission) return null;

    return (
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <Button onClick={() => setView("list")} className="mb-4">
            <ArrowLeft className="mr-2 h-4 w-4" />
            Back to List
          </Button>
          <h2 className="text-2xl font-bold">
            Submission Details: {selectedSubmission.website}
          </h2>
        </div>

        <PostDetails
          selectedSubmission={selectedSubmission}
          contentIdeas={contentIdeas}
          selectedIdeas={selectedIdeas}
          generatedPosts={generatedPosts}
          isGeneratingIdeas={isGeneratingIdeasInPopup}
          isScrapingPosts={isScrapingPosts}
          isGeneratingPosts={isGeneratingPosts}
          handleIdeaSelection={handleIdeaSelection}
          generateContentIdeas={generateContentIdeas}
          scrapeLinkedInPosts={scrapeLinkedInPosts}
          generateFullPosts={generateFullPosts}
          createTrelloCard={createTrelloCard}
          supabase={supabase}
          contentIdeasRef={contentIdeasRef}
          selectedIdeasRef={selectedIdeasRef}
          generatedPostsRef={generatedPostsRef}
          scrollToRef={scrollToRef}
          onUpdate={(updatedPosts) => {
            setSelectedSubmission((prev) => ({
              ...prev,
              ...updatedPosts,
            }));
          }}
        />
      </div>
    );
  };

  if (!isAuthenticated) {
    return (
      <div className="container mx-auto px-4 py-8 max-w-md">
        <h1 className="text-4xl font-bold mb-8 text-center">Admin Login</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Password
            </label>
            <Input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full"
              placeholder="Enter password"
            />
          </div>
          {error && (
            <Alert variant="destructive">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          <Button
            type="submit"
            className="w-full flex items-center justify-center"
          >
            <Lock className="mr-2" />
            Login
          </Button>
        </form>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-4xl font-bold">Admin Dashboard</h1>
        <Button
          onClick={handleLogout}
          variant="outline"
          className="flex items-center hover:bg-gray-100"
        >
          <Unlock className="mr-2" />
          Logout
        </Button>
      </div>

      {view === "list" ? renderListView() : renderDetailView()}
    </div>
  );
};

export default AdminPage;
