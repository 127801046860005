import { Alert, AlertDescription } from "./ui/alert";
import { motion } from "framer-motion";

const FloatingAlert = ({ variant = "warning", children }) => {
  const alertStyles = {
    warning: "bg-yellow-50 border-2 border-yellow-500",
    success: "bg-green-50 border-2 border-green-500",
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      className="fixed bottom-20 left-4 right-4 md:static md:mb-4 z-40"
    >
      <Alert
        className={`${alertStyles[variant]} shadow-lg rounded-lg mx-auto max-w-md`}
      >
        <AlertDescription className="text-sm font-medium">
          {children}
        </AlertDescription>
      </Alert>
    </motion.div>
  );
};

export default FloatingAlert;
