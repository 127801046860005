// custom-toaster.js
import { Toast, ToastProvider, ToastViewport } from "./toast";
import { useToast } from "./use-toast";
import { motion, AnimatePresence } from "framer-motion";
import { CheckCircle, AlertCircle, Info } from "lucide-react";

const toastVariants = {
  initial: { opacity: 0, y: -20, scale: 0.95 },
  animate: { opacity: 1, y: 0, scale: 1 },
  exit: { opacity: 0, y: -20, scale: 0.95 },
};

const getToastIcon = (variant) => {
  switch (variant) {
    case "success":
      return <CheckCircle className="w-5 h-5 text-green-500" />;
    case "destructive":
      return <AlertCircle className="w-5 h-5 text-red-500" />;
    default:
      return <Info className="w-5 h-5 text-blue-500" />;
  }
};

const getToastStyles = (variant) => {
  const baseStyles =
    "border-4 mb-4 shadow-[4px_4px_0_0_#000] min-w-[320px] max-w-[420px] p-4";

  switch (variant) {
    case "success":
      return `${baseStyles} bg-green-50 border-green-500`;
    case "destructive":
      return `${baseStyles} bg-red-50 border-red-500`;
    default:
      return `${baseStyles} bg-white border-black`;
  }
};

export function CustomToaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      <div className="fixed top-4  right-4 z-[9999] flex flex-col gap-2 max-h-screen overflow-auto">
        <AnimatePresence mode="popLayout">
          {toasts.map(({ id, title, description, variant, ...props }) => (
            <motion.div
              key={id}
              layout
              variants={toastVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ type: "spring", damping: 20, stiffness: 300 }}
            >
              <Toast {...props} className={getToastStyles(variant)}>
                <div className="flex items-start gap-3">
                  {getToastIcon(variant)}
                  <div className="flex-1 grid gap-1">
                    {title && (
                      <div className="font-bold text-base">{title}</div>
                    )}
                    {description && (
                      <div className="text-sm text-gray-600">{description}</div>
                    )}
                  </div>
                </div>
              </Toast>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
      <ToastViewport />
    </ToastProvider>
  );
}
