import React from "react";

const StepIndicator = ({
  step,
  steps,
  formData,
  uploadedFiles,
  interviewAnswers,
}) => {
  // Helper function to check step completion
  const isStepComplete = (stepIndex) => {
    switch (stepIndex) {
      case 0: // Brand Information
        return Boolean(formData.website && formData.email && formData.industry);

      case 1: // Upload Assets
        return Boolean(
          (uploadedFiles.teamPhotos && uploadedFiles.teamPhotos.length > 0) ||
            (uploadedFiles.brandManual && uploadedFiles.brandManual.length > 0)
        );

      case 2: // Interview
        const answeredQuestions = Object.keys(interviewAnswers || {}).length;
        return answeredQuestions >= 4;

      default:
        return false;
    }
  };

  return (
    <div className="flex-grow flex items-center justify-between relative mx-8">
      {steps.map((stepLabel, index) => (
        <div key={index} className="flex flex-col items-center z-10 bg-white">
          <div
            className={`flex items-center justify-center w-6 h-6 sm:w-8 sm:h-8 mb-1 sm:mb-2 
              ${
                step > index + 1 || isStepComplete(index)
                  ? "text-black"
                  : step === index + 1
                  ? "text-black"
                  : "text-gray-500"
              } text-xs sm:text-sm font-bold`}
          >
            {step > index + 1 || isStepComplete(index) ? (
              <div className="inline-flex items-center justify-center w-6 h-6 border-2 border-black rounded-sm bg-green-500">
                <svg
                  className="w-4 h-4 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
            ) : (
              <div className="inline-flex items-center justify-center w-6 h-6 border-2 border-gray-300 rounded-md bg-white">
                <div></div>
              </div>
            )}
          </div>
          <span
            className={`text-[10px] sm:text-xs text-center
              ${
                step > index + 1 || isStepComplete(index)
                  ? "text-black font-bold"
                  : step === index + 1
                  ? "text-black font-bold"
                  : "text-gray-500"
              } hidden sm:block`}
          >
            {stepLabel}
          </span>
        </div>
      ))}
      {/* Divider line */}
      <div className="absolute top-3 sm:top-4 left-0 right-0 h-0.5 bg-gray-200" />
    </div>
  );
};

export default StepIndicator;
