import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useMediaQuery } from "../lib/useMediaQuery";

import axios from "axios";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { createClient } from "@supabase/supabase-js";
import { Textarea } from "./ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { useToast } from "./ui/use-toast";
import { CustomToaster } from "./ui/custom-toaster";
import TopicBasedInterview from "./TopicBasedInterview";

import {
  Loader2,
  ChevronLeft,
  ChevronRight,
  CheckCircle,
  Info,
  SkipForward,
  ArrowDown,
  Files,
} from "lucide-react";
import { Alert, AlertDescription } from "./ui/alert";
import { createTrelloBoard, createTrelloCard } from "./trelloIntegration";
import SpeechToTextApp from "./SpeechToTextApp";
import PersistentForm from "src/components/PersistentForm";
import { initialFormState } from "src/lib/formStates";
import FileUpload from "src/components/FileUpload";
import FormField from "./FormField";
import StepIndicator from "./StepIndicator";
import PostExamples from "./PostExamples";
import { generateQuestions } from "../lib/url-helpers";
import FloatingAlert from "./FloatingAlert";
import EmailConfirmationDialog from "./EmailConfirmationDialog";

const supabase = createClient(
  "https://jhknbuxutiqdvlxnvawv.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Impoa25idXh1dGlxZHZseG52YXd2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjcyOTgxMDYsImV4cCI6MjA0Mjg3NDEwNn0.l3K1xIyN2uZHUrXvjSGr_UXW2s2n9dQvXz8kB_x4K1E"
);

// Trello configuration
const TRELLO_API_KEY = "c987a9e5db3aa4875ba07636997b8263";
const TRELLO_TOKEN =
  "ATTAdef27c382d566a02e494f8911d0889e874f0ac61115f2043b79ec8099dc85776359ED2E6";

const MainContent = () => {
  const [step, setStep] = useState(1);
  const steps = ["Brand Information", "Upload Assets", "Interview"];

  const [questions, setQuestions] = useState([]);
  const [isQuestionsLoading, setIsQuestionsLoading] = useState(true);

  const [formData, setFormData] = useState(initialFormState);

  useEffect(() => {
    if (formData.website) {
      const generatedQuestions = generateQuestions(formData.website);
      setQuestions(generatedQuestions);
    }
    setIsQuestionsLoading(false);
  }, [formData.website]);

  const handleFormDataChange = useCallback((newData) => {
    setFormData(newData);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // Fallback for older browsers
    if (typeof window.scrollTo !== "function") {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0; // For Safari
    }
  };

  const [contentIdeas, setContentIdeas] = useState([]);
  const [selectedIdeas, setSelectedIdeas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasBoardBeenCreated, setHasBoardBeenCreated] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState({
    teamPhotos: [],
    brandManual: [],
  });
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [hasStartedInterview, setHasStartedInterview] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isInterviewComplete, setIsInterviewComplete] = useState(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);

  useEffect(() => {
    if (step > 1) {
      // Only scroll when moving forward
      setTimeout(() => {
        scrollToTop();
      }, 100);
    }
  }, [step]);

  useEffect(() => {
    const initializeQuestions = async () => {
      setIsQuestionsLoading(true);
      try {
        const formId = searchParams.get("formId");

        if (formId) {
          // First try to get existing questions from the database
          const { data: submission, error } = await supabase
            .from("form_submissions")
            .select("interview_questions")
            .eq("id", formId)
            .single();

          if (error) throw error;

          if (submission?.interview_questions?.length > 0) {
            // If we have existing questions in the database, use those
            setQuestions(submission.interview_questions.map((q) => q.question));
          } else {
            // If no existing questions, generate new ones
            const generatedQuestions = generateQuestions(
              formData.website || ""
            );
            setQuestions(generatedQuestions);
          }
        } else {
          // If no formId (new submission), generate default questions
          const generatedQuestions = generateQuestions(formData.website || "");
          setQuestions(generatedQuestions);
        }
      } catch (error) {
        console.error("Error initializing questions:", error);
        // Fallback to generated questions if there's an error
        const generatedQuestions = generateQuestions(formData.website || "");
        setQuestions(generatedQuestions);
      } finally {
        setIsQuestionsLoading(false);
      }
    };

    initializeQuestions();
  }, [formData.website, searchParams]); // Dependencies that should trigger re-initialization

  const ORGANIZATION_ID = "6710f0bf4b5e72a5b06483d0";
  const stepDescriptions = [
    "In 3 steps, you'll provide us with the information, insights, and assets we need to create 8 LinkedIn posts per month for your personal profile. The more precise and comprehensive the information and assets you share, the more authentic and high-quality your LinkedIn posts will be.",
    <>
      <p className="mb-6">
        Include photos of yourself at conferences, selfies, moments with the
        team during leisure time, or professional headshots—any images you'd
        like to share on LinkedIn.
      </p>
      <p className="mb-6">
        <strong>These photos will enhance your LinkedIn posts</strong>, as
        images with faces receive 4x higher engagement. Phone photos with
        natural surroundings achieve 1.5x better engagement , while graphic
        images with headlines typically perform lower—though a compelling
        headline can make a big difference.
      </p>
      <p>Of course, sharing photos of yourself is optional!</p>
    </>,
    <>
      <p className="mb-6">
        This interview is the final step in providing us with the information we
        need to create authentic LinkedIn thought leadership posts for you.
      </p>
      <p className="mb-6">
        Be descriptive, honest, and share everything you’d like included in your
        posts. Feel free to make mistakes or repeat yourself—you can always
        re-record if you’re not satisfied with your answer. Aim to speak for at
        least 30 seconds to give a thorough response.
      </p>
    </>,
  ];

  const { toast } = useToast();

  useEffect(() => {
    const loadSavedFiles = async () => {
      const formId = searchParams.get("formId");
      if (formId) {
        try {
          const { data: submission, error } = await supabase
            .from("form_submissions")
            .select("team_photos, brand_manual")
            .eq("id", formId)
            .single();

          if (error) throw error;

          if (submission) {
            setUploadedFiles({
              teamPhotos: submission.team_photos || [],
              brandManual: submission.brand_manual || [],
            });
          }
        } catch (error) {
          console.error("Error loading saved files:", error);
        }
      }
    };

    loadSavedFiles();
  }, [searchParams]);

  // In MainContent.js, update the updateInterviewAnswer function
  const updateInterviewAnswer = async (questionIndex, answer) => {
    console.log(`Updating answer for question ${questionIndex}:`, answer);

    // Ensure answer is a string or empty string
    const finalAnswer = answer?.toString() || "";

    // Update local state
    setFormData((prevData) => {
      // Create a new answers object if it doesn't exist
      const currentAnswers = prevData.interviewAnswers || {};

      // Create new object with updated answer
      const newAnswers = {
        ...currentAnswers,
        [questionIndex]: finalAnswer,
      };

      // Ensure all previous questions have at least empty string answers
      for (let i = 0; i < questionIndex; i++) {
        if (!(i in newAnswers)) {
          newAnswers[i] = "";
        }
      }

      return {
        ...prevData,
        interviewAnswers: newAnswers,
      };
    });

    // Update Supabase
    const formId = searchParams.get("formId");
    if (formId) {
      try {
        // First get current interview answers from Supabase
        const { data: currentSubmission } = await supabase
          .from("form_submissions")
          .select("interview_answers, interview_questions")
          .eq("id", formId)
          .single();

        // Create updated array of answers
        let updatedAnswers = Array(questions.length).fill("");

        if (currentSubmission?.interview_answers) {
          // If we have existing answers, spread them into our array
          const existingAnswers = currentSubmission.interview_answers;
          existingAnswers.forEach((ans, idx) => {
            updatedAnswers[idx] = ans || "";
          });
        }

        // Update the specific answer
        updatedAnswers[questionIndex] = finalAnswer;

        // Get current questions for this interview
        const currentQuestions = questions.map((q) => ({
          question: q,
          timestamp: new Date().toISOString(),
        }));

        // Update both answers and questions in Supabase
        const { error: updateError } = await supabase
          .from("form_submissions")
          .update({
            interview_answers: updatedAnswers,
            interview_questions: currentQuestions,
          })
          .eq("id", formId);

        if (updateError) {
          console.error("Error updating interview answer:", updateError);
          toast({
            title: "Error",
            description: "Failed to save your answer. Please try again.",
            variant: "destructive",
          });
        }
      } catch (error) {
        console.error("Error saving interview answer:", error);
        toast({
          title: "Error",
          description: "Failed to save your answer. Please try again.",
          variant: "destructive",
        });
      }
    }
  };

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleUploadComplete = async (type, fileInfo) => {
    setUploadedFiles((prev) => ({
      ...prev,
      [type]: [...(prev[type] || []), fileInfo],
    }));

    // Save to database
    const formId = searchParams.get("formId");
    if (formId) {
      try {
        // First get current files from database
        const { data: currentSubmission } = await supabase
          .from("form_submissions")
          .select(type === "teamPhotos" ? "team_photos" : "brand_manual")
          .eq("id", formId)
          .single();

        // Prepare the update object
        const currentFiles =
          currentSubmission?.[
            type === "teamPhotos" ? "team_photos" : "brand_manual"
          ] || [];
        const updatedFiles = [...currentFiles, fileInfo];

        // Update in database
        const { error } = await supabase
          .from("form_submissions")
          .update({
            [type === "teamPhotos" ? "team_photos" : "brand_manual"]:
              updatedFiles,
          })
          .eq("id", formId);

        if (error) throw error;
      } catch (error) {
        console.error("Error saving file info to database:", error);
        toast({
          title: "Error",
          description: "Failed to save file information. Please try again.",
          variant: "destructive",
        });
      }
    }
  };

  const handleContinue = async () => {
    if (step === 1) {
      setIsLoading(true);
      setError(null);

      try {
        const formId = searchParams.get("formId");
        setStep(2);

        if (!formId) {
          setTimeout(() => {
            setIsEmailDialogOpen(true);
          }, 200);

          // Create new form submission
          const { data: newSubmission, error: submissionError } = await supabase
            .from("form_submissions")
            .insert([
              {
                website: formData.website,
                email: formData.email,
                industry: formData.industry,
                icp: formData.icp,
                linkedin_profile: formData.linkedinProfile,
                created_at: new Date().toISOString(),
              },
            ])
            .select()
            .single();

          if (submissionError) throw submissionError;
          setSearchParams({ formId: newSubmission.id });

          // Create Trello board for new submission
          const boardData = await createTrelloBoard(formData, ORGANIZATION_ID);

          const { error: trelloUpdateError } = await supabase
            .from("form_submissions")
            .update({
              trello_board_id: boardData.id,
              trello_todo_list_id: boardData.lists["To Do"],
              trello_form_list_id: boardData.lists["Form Submission"],
              trello_board_url: boardData.url,
            })
            .eq("id", newSubmission.id);

          if (trelloUpdateError) throw trelloUpdateError;

          setFormData((prev) => ({
            ...prev,
            trelloBoardId: boardData.id,
            trelloTodoListId: boardData.lists["To Do"],
            trelloFormListId: boardData.lists["Form Submission"],
            trelloBoardUrl: boardData.url,
          }));
          setHasBoardBeenCreated(true);

          // Send confirmation email only for new submissions
          try {
            const emailNotifResponse = await fetch("/api/send-notification", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                to:
                  process.env.NODE_ENV === "development"
                    ? "svojtko1@gmail.com"
                    : "poksteflb@gmail.com",
                subject: `New Form Submission: ${
                  formData.website || "Unknown Website"
                }`,
                formData: {
                  ...formData,
                  trelloBoardUrl: boardData.url,
                },
              }),
            });

            if (!emailNotifResponse.ok) {
              console.error("Failed to send notification email");
            }

            const emailResponse = await fetch("/api/send-email", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                formData: {
                  ...formData,
                  formId: newSubmission.id,
                },
              }),
            });

            if (!emailResponse.ok) {
              console.error("Failed to send confirmation email");
            }
          } catch (emailError) {
            console.error("Error sending confirmation email:", emailError);
          }
        } else {
          // Update existing submission
          const { error: updateError } = await supabase
            .from("form_submissions")
            .update({
              website: formData.website,
              email: formData.email,
              industry: formData.industry,
              usp: formData.usp,
              icp: formData.icp,
              linkedin_profile: formData.linkedinProfile,
              topics_to_talk_about: formData.topicsToTalkAbout,
            })
            .eq("id", formId);

          if (updateError) throw updateError;
        }
      } catch (error) {
        console.error("Error handling form continuation:", error);
        setError("Failed to process form data. Please try again.");
        toast({
          title: "Error",
          description: "Failed to process form data. Please try again.",
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    } else if (step === 2) {
      setIsLoading(true);
      setError(null);

      try {
        setStep(3);
        setTimeout(() => {
          scrollToTop();
        }, 100);
      } catch (error) {
        console.error("Error saving step 2 data:", error);
        toast({
          title: "Error",
          description: "Failed to save your progress. Please try again.",
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      setStep((prev) => prev + 1);
      setTimeout(() => {
        scrollToTop();
      }, 100);
    }
  };

  const handleRemoveFile = async (type, fileInfo) => {
    try {
      // Just delete the card itself since deleting the card will remove all attachments
      const deleteCardParams = new URLSearchParams({
        key: TRELLO_API_KEY,
        token: TRELLO_TOKEN,
      });

      const cardResponse = await fetch(
        `https://api.trello.com/1/cards/${fileInfo.cardId}?${deleteCardParams}`,
        { method: "DELETE" }
      );

      if (!cardResponse.ok) {
        throw new Error(`Failed to delete card: ${cardResponse.statusText}`);
      }

      // Remove from local state
      setUploadedFiles((prev) => ({
        ...prev,
        [type]: prev[type].filter((f) => f.cardId !== fileInfo.cardId),
      }));

      // Remove from database
      const formId = searchParams.get("formId");
      if (formId) {
        const { data: currentSubmission } = await supabase
          .from("form_submissions")
          .select(type === "teamPhotos" ? "team_photos" : "brand_manual")
          .eq("id", formId)
          .single();

        const currentFiles =
          currentSubmission?.[
            type === "teamPhotos" ? "team_photos" : "brand_manual"
          ] || [];
        const updatedFiles = currentFiles.filter(
          (f) => f.cardId !== fileInfo.cardId
        );

        const { error } = await supabase
          .from("form_submissions")
          .update({
            [type === "teamPhotos" ? "team_photos" : "brand_manual"]:
              updatedFiles,
          })
          .eq("id", formId);

        if (error) throw error;
      }

      toast({
        title: "Success",
        description: "File removed successfully",
        variant: "success",
      });
    } catch (error) {
      console.error("Error removing file:", error);
      toast({
        title: "Error",
        description: `Failed to remove file: ${error.message}`,
        variant: "destructive",
      });
    }
  };

  const handleInterviewComplete = async () => {
    const answeredCount = Object.keys(formData.interviewAnswers || {}).length;

    if (answeredCount < 4) {
      toast({
        title: "More answers needed",
        description:
          "Please answer at least 4 questions before completing the interview.",
        variant: "destructive",
      });
      return;
    }

    setIsLoading(true);

    try {
      const formId = searchParams.get("formId");

      if (formId) {
        // Update final interview answers in Supabase
        const { error: updateError } = await supabase
          .from("form_submissions")
          .update({
            interview_answers: Object.values(formData.interviewAnswers),
          })
          .eq("id", formId);

        if (updateError) throw updateError;
      }

      setIsInterviewComplete(true);
      toast({
        title: "Interview Completed!",
        description: "Your answers have been saved successfully.",
        variant: "success",
      });

      // Move to next step or submit
      handleSubmit();
    } catch (error) {
      console.error("Error completing interview:", error);
      toast({
        title: "Error",
        description: "Failed to save interview answers. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setIsLoading(true);
    setError(null);

    try {
      if (!formData.trelloBoardId) {
        throw new Error("No Trello board ID found. Please try again.");
      }

      // Get the current questions from the database to ensure we have the complete data
      const { data: submissionData, error: fetchError } = await supabase
        .from("form_submissions")
        .select("interview_questions, interview_answers")
        .eq("id", searchParams.get("formId"))
        .single();

      if (fetchError) throw fetchError;

      // Create Trello card for form submission in the existing board
      const cardData = {
        name: `Form Submission: ${formData.website || "Unknown"}`,
        desc: generateCardDescription({
          ...formData,
          interview_questions: submissionData.interview_questions,
          interview_answers: submissionData.interview_answers,
        }),
        idList: formData.trelloFormListId,
      };

      console.log("Creating Trello card with data:", cardData);
      const cardResponse = await createTrelloCard(
        cardData,
        formData.trelloBoardId,
        "Form Submission"
      );
      console.log("Trello card created:", cardResponse);

      if (error) throw error;

      setIsSubmitSuccessful(true);
      setStep(5);
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setError(`Failed to submit form: ${error.message}`);
      toast({
        title: "Error",
        description: "Failed to submit form. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
      setIsSubmitting(false);
    }
  };

  const generateCardDescription = (formData) => {
    // Format interview Q&A pairs
    const interviewQA =
      formData.interview_questions
        ?.map((questionObj, index) => {
          const answer =
            formData.interviewAnswers[index] || "No answer provided";
          return `Question ${index + 1}: ${
            questionObj.question
          }\nAnswer: ${answer}\n`;
        })
        .join("\n") || "No interview data available";

    // Format LinkedIn posts section (keep existing code)
    const linkedInPosts = [1, 2, 3, 4, 5]
      .map((num) => {
        const post = formData[`lastPost${num}`];
        return post ? `- Post ${num}: ${post}` : `- Post ${num}: Not provided`;
      })
      .join("\n");

    return `
  Form Submission
  
  Website: ${formData.website || "Not provided"}
  Email: ${formData.email || "Not provided"}
  Industry: ${formData.industry || "Not provided"}
  ICP + Personas: ${formData.icp || "Not provided"}
  LinkedIn Profile: ${formData.linkedinProfile || "Not provided"}
  Topics to Talk About: ${formData.topicsToTalkAbout || "Not provided"}
  
  Interview Questions & Answers:
  ${interviewQA}
  
  Generated on: ${new Date().toISOString()}
    `.trim();
  };

  const renderStepContent = () => {
    if (isSubmitting) {
      return (
        <div className="flex flex-col items-center justify-center py-12">
          <Loader2 className="h-12 w-12 animate-spin text-[#f8bf5d]" />
          <h3 className="text-2xl font-bold mt-4 text-black">
            Processing your submission...
          </h3>
          <p className="text-gray-600 mt-2">
            This may take a few moments. Please don't close this page.
          </p>
        </div>
      );
    }

    switch (step) {
      case 1:
        return (
          <div className="space-y-8">
            <FormField
              label="Website"
              name="website"
              value={formData.website}
              onChange={handleInputChange}
              placeholder="e.g., www.yourcompany.com"
              required
              tooltipContent="Your company's main website"
            />
            <FormField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter your email to receive updates"
              type="email"
              required
              tooltipContent="We'll send updates about your content to this email address"
            />
            <FormField
              label="Industry"
              name="industry"
              value={formData.industry}
              onChange={handleInputChange}
              placeholder="e.g., Technology, Healthcare, Finance"
              required
              tooltipContent="The primary industry your business operates in"
            />
            <FormField
              label="LinkedIn profile you wish to imitate"
              name="linkedinProfile"
              value={formData.linkedinProfile}
              onChange={handleInputChange}
              placeholder="e.g., https://www.linkedin.com/in/yourname"
              tooltipContent="We’ll align the tone of voice and writing style to match the posts already published on this profile."
            />
          </div>
        );
      case 2:
        return (
          <div className="space-y-8">
            <div className="flex justify-center">
              <PostExamples />
            </div>

            <FileUpload
              label="Photos"
              type="images" // This will accept all image formats
              multiple={true}
              tooltipContent="Upload photos of your team, events, or products"
              onRemove={(fileInfo) => handleRemoveFile("teamPhotos", fileInfo)}
              boardId={formData.trelloFormListId}
              uploadedFiles={uploadedFiles.teamPhotos}
              onUploadComplete={(fileName, fileInfo) =>
                handleUploadComplete("teamPhotos", fileInfo)
              }
              onUploadError={(fileName, error) => {
                console.error("Upload error:", fileName, error);
                toast({
                  title: "Error",
                  description: `Failed to upload ${fileName}`,
                  variant: "destructive",
                });
              }}
            />
          </div>
        );
      // In MainContent.js, update case 3 in renderStepContent:
      case 3:
        const answeredCount = Object.keys(
          formData.interviewAnswers || {}
        ).length;
        const minimumRequired = 4;

        return (
          <div className="space-y-8">
            <TopicBasedInterview
              onTopicsGenerated={(questions) => {
                setQuestions(questions);
              }}
              // Pass the initial topics from formData
              initialTopics={
                formData.topicsToTalkAbout
                  ? formData.topicsToTalkAbout.split(", ").filter(Boolean)
                  : []
              }
            />
            <SpeechToTextApp
              onAnswerRecorded={updateInterviewAnswer}
              onComplete={handleInterviewComplete}
              questions={questions}
              currentQuestionIndex={currentQuestionIndex}
              setCurrentQuestionIndex={setCurrentQuestionIndex}
              answers={formData.interviewAnswers}
              hasStartedInterview={hasStartedInterview}
              setHasStartedInterview={setHasStartedInterview}
              isQuestionsLoading={isQuestionsLoading} // renamed from isLoading
            />

            {answeredCount < minimumRequired ? (
              <FloatingAlert variant="warning">
                You need to answer at least {minimumRequired} questions.
              </FloatingAlert>
            ) : (
              <></>
            )}
          </div>
        );
      case 5:
        return (
          <div className="text-center py-8">
            <CheckCircle className="h-16 w-16 text-green-500 mx-auto mb-4" />
            <h3 className="text-2xl font-bold mb-4">Thank You!</h3>
            <p className="text-xl mb-4">
              Your form has been successfully submitted.
            </p>
            <p className="text-lg">
              You'll receive an email notification within 3 days with your
              custom content strategy.
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <PersistentForm onFormDataChange={handleFormDataChange} currentStep={step}>
      <div className="min-h-screen bg-white flex flex-col relative">
        <CustomToaster />

        {/* Video Guide Button */}
        <a
          href="https://www.youtube.com/watch?v=y4ehKcYjcuA"
          target="_blank"
          rel="noopener noreferrer"
          className="fixed top-4 right-4 z-50 inline-flex items-center justify-center px-4 py-2 bg-red-600 hover:bg-red-700 text-white font-semibold rounded-md transition duration-300 ease-in-out transform hover:scale-105 shadow-md"
        >
          <svg
            className="w-6 h-6 mr-2"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
          </svg>
          Quick video guide
        </a>
        {/* Main Content */}
        <main className="flex-grow flex flex-row items-start justify-center pb-32 px-4">
          <div className="w-full pt-20 max-w-4xl">
            {/* Title */}
            <h2 className="text-4xl sm:text-5xl md:text-6xl font-black uppercase text-center mb-8 relative">
              <span className="absolute inset-0 text-yellow-400 transform translate-x-0.5 translate-y-0.5">
                {steps[step - 1]}
              </span>
              <span className="relative text-black">{steps[step - 1]}</span>
            </h2>

            {/* Description */}
            <p className="text-lg text-gray-700 mb-12 text-center max-w-3xl mx-auto leading-relaxed space-y-4">
              {" "}
              {stepDescriptions[step - 1]}
            </p>

            {/* Error Alert */}
            {error && (
              <Alert
                variant="destructive"
                className="mb-8 bg-red-500 text-white border-4 border-black"
              >
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            {/* Step Content */}
            {renderStepContent()}
          </div>
        </main>
        {/* Bottom Navigation */}
        <div className="fixed bottom-0 z-50 w-full bg-white border-black border-t-4">
          <div className="flex items-center justify-between max-w-4xl mx-auto h-24 px-4">
            {/* Back Button */}
            <Button
              onClick={() => setStep((prev) => prev - 1)}
              variant="outline"
              className={`
        bg-white border-4 border-black text-black
        p-2 sm:px-8 sm:py-4 hover:bg-gray-100 
        shadow-[4px_4px_0_0_#000] sm:shadow-[6px_6px_0_0_#000]
        ${step === 1 || step === 5 ? "hidden" : ""}
      `}
            >
              <ChevronLeft className="h-6 w-6 sm:hidden" />
              <span className="hidden sm:inline">Back</span>
            </Button>

            {/* Step Progress Indicators - Adjusted margins */}
            <div className="mx-2 sm:mx-8 flex-grow">
              <StepIndicator
                step={step}
                steps={steps}
                formData={formData}
                uploadedFiles={uploadedFiles}
                interviewAnswers={formData.interviewAnswers}
              />
            </div>

            {step === 3 ? (
              // Finish Interview Button for step 3
              <div className="relative">
                <Button
                  onClick={handleInterviewComplete}
                  disabled={
                    Object.values(formData.interviewAnswers || {}).filter(
                      (answer) => answer && answer.trim() !== ""
                    ).length < 4
                  }
                  className={`
        bg-[#f8bf5d] border-4 border-black text-black 
        p-2 sm:px-8 sm:py-4
        flex items-center text-sm sm:text-xl
        hover:bg-[#FFB52E] 
        shadow-[4px_4px_0_0_#000] sm:shadow-[6px_6px_0_0_#000]
        transition-all duration-200 
        hover:translate-x-1 hover:translate-y-1 
        hover:shadow-[2px_2px_0_0_#000] sm:hover:shadow-[3px_3px_0_0_#000]
        disabled:opacity-50 disabled:cursor-not-allowed
        whitespace-nowrap
        w-full justify-center
      `}
                >
                  {isSubmitting ? (
                    <>
                      <Loader2 className="h-6 w-6 animate-spin" />
                      <span className="hidden sm:inline ml-2">
                        Submitting...
                      </span>
                    </>
                  ) : (
                    <>
                      {/* <ChevronRight className="h-6 w-6 sm:mr-2" /> */}
                      <span className="hidden sm:inline">Finish & Submit</span>
                    </>
                  )}
                </Button>

                {/* Add Skip button with new behavior */}
                <button
                  onClick={() => {
                    toast({
                      title: "Progress Saved",
                      description:
                        "You can return anytime to complete the interview. Your progress has been automatically saved.",
                      duration: 5000,
                      variant: "info",
                    });
                    // Optional: you might want to redirect to a specific page or close the window
                    // window.close(); // if you want to close the window
                    // or navigate somewhere
                    // navigate('/'); // if you want to go to home page
                  }}
                  className="mt-2 text-sm text-gray-500 hover:text-gray-700 flex items-center justify-center gap-1 w-full transition-colors duration-200"
                >
                  <span>Skip & Finish later</span>
                  <ChevronRight className="h-4 w-4" />
                </button>
              </div>
            ) : step === 5 ? null : (
              // Buttons for steps 1 and 2
              <div className="flex flex-col items-center space-y-3">
                <Button
                  onClick={handleContinue}
                  disabled={
                    isLoading ||
                    (step === 1 &&
                      (!formData.website ||
                        !formData.email ||
                        !formData.industry))
                  }
                  className={`
        bg-[#f8bf5d] border-4 border-black text-black 
        p-1 sm:px-8 sm:py-4
        flex items-center text-sm sm:text-xl
        hover:bg-[#FFB52E] 
        shadow-[4px_4px_0_0_#000] sm:shadow-[6px_6px_0_0_#000]
        transition-all duration-200 
        hover:translate-x-1 hover:translate-y-1 
        hover:shadow-[2px_2px_0_0_#000] sm:hover:shadow-[3px_3px_0_0_#000]
        disabled:opacity-50 disabled:cursor-not-allowed
        whitespace-nowrap
        w-full justify-center
      `}
                >
                  {isLoading ? (
                    <>
                      <Loader2 className="h-6 w-6 animate-spin" />
                      <span className="hidden sm:inline ml-2">
                        {step === 1 ? "Saving..." : "Uploading..."}
                      </span>
                    </>
                  ) : (
                    <>
                      <ChevronRight className="h-6 w-6 sm:mr-2" />
                      <span className="hidden sm:inline">
                        Submit & Continue
                      </span>
                    </>
                  )}
                </Button>

                {step === 2 && (
                  <button
                    onClick={handleContinue}
                    className="text-sm text-gray-500 hover:text-gray-700 flex items-center gap-1 transition-colors"
                  >
                    <ChevronRight className="h-4 w-4" />
                    Skip & Finish later
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <EmailConfirmationDialog
        isOpen={isEmailDialogOpen}
        onClose={() => setIsEmailDialogOpen(false)}
        email={formData.email}
      />
    </PersistentForm>
  );
};

export default MainContent;
