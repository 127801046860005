export const extractCompanyName = (url) => {
  if (!url) return "";

  try {
    let domain = url.replace(/(^\w+:|^)\/\//, "").replace("www.", "");
    domain = domain.split(".")[0];
    return domain
      .split(/[-_]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  } catch (error) {
    console.error("Error extracting company name:", error);
    return "";
  }
};

export const generateQuestions = (website) => {
  const companyName = extractCompanyName(website) || "your company";

  return [
    `**What is your story? **How has your professional journey started?`,
    `**What makes you better than your competitors? **The one thing?`,
    "What is your target audience on LinkedIn?",
  ];
};
