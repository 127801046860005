import React, { useState, useRef, useEffect } from "react";
import { Button } from "./ui/button";
import { Card, CardContent } from "./ui/card";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Mic, Check, Type, MessageSquare } from "lucide-react";
import { useToast } from "./ui/use-toast";
import { Textarea } from "./ui/textarea";

const SpeechToTextApp = ({
  onAnswerRecorded,
  onComplete,
  questions,
  currentQuestionIndex,
  setCurrentQuestionIndex,
  answers = {},
  hasStartedInterview = false,
  setHasStartedInterview,
  isQuestionsLoading,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [hasRecorded, setHasRecorded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAllQuestions, setShowAllQuestions] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [textAnswer, setTextAnswer] = useState("");
  const initialQuestionsCount = 4;
  const visibleQuestions = showAllQuestions
    ? questions
    : questions.slice(0, initialQuestionsCount);
  // Only show questions after we have more than 3 questions (base questions)
  const hasGeneratedQuestions = questions.length > 3;
  const [activeQuestionIndex, setActiveQuestionIndex] =
    useState(currentQuestionIndex);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const { toast } = useToast();

  useEffect(() => {
    setHasRecorded(Boolean(answers[currentQuestionIndex]));
    setActiveQuestionIndex(currentQuestionIndex);
  }, [currentQuestionIndex, answers]);

  const handleTextSubmit = (questionIndex) => {
    if (!textAnswer.trim()) {
      toast({
        title: "Error",
        description: "Please enter your answer before submitting.",
        variant: "destructive",
      });
      return;
    }

    // Ensure we're passing the trimmed answer
    onAnswerRecorded(questionIndex, textAnswer.trim() || "");
    setHasRecorded(true);
    setHasStartedInterview(true);
    setIsTyping(false);
    setTextAnswer("");

    if (
      questionIndex === currentQuestionIndex &&
      questionIndex < questions.length - 1
    ) {
      setTimeout(() => {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }, 1000);
    }
  };

  const toggleInputMethod = (questionIndex) => {
    if (isRecording) {
      stopRecording();
    }
    setIsTyping(!isTyping);
    setActiveQuestionIndex(questionIndex);
    // Set the textAnswer to the existing answer when switching to typing mode
    if (!isTyping && answers[questionIndex]) {
      setTextAnswer(answers[questionIndex]);
    } else {
      setTextAnswer("");
    }
    setTimeout(() => {
      document
        .querySelector(`textarea[data-question="${questionIndex}"]`)
        ?.focus();
    }, 0);
  };

  const startRecording = async (questionIndex) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });
        sendAudioToAPI(audioBlob, questionIndex);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      setHasRecorded(false);
      setHasStartedInterview(true);
      setActiveQuestionIndex(questionIndex);

      toast({
        title: "Recording Started",
        description: "Speak clearly into your microphone.",
      });
    } catch (error) {
      console.error("Error accessing microphone:", error);
      toast({
        title: "Error",
        description:
          "Unable to access microphone. Please check your permissions.",
        variant: "destructive",
      });
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      toast({
        title: "Recording Stopped",
        description: "Processing your answer...",
      });
    }
  };

  const sendAudioToAPI = async (audioBlob, questionIndex) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", audioBlob, "audio.wav");

    try {
      const response = await fetch("/api/transcribe", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      if (result && result.text) {
        // Ensure we're passing a trimmed string or empty string
        onAnswerRecorded(questionIndex, result.text.trim() || "");
        setHasRecorded(true);

        if (
          questionIndex === currentQuestionIndex &&
          questionIndex < questions.length - 1
        ) {
          setTimeout(() => {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
          }, 1000);
        }
      } else {
        throw new Error("Failed to get transcription");
      }
    } catch (error) {
      console.error("Error transcribing audio:", error);
      toast({
        title: "Transcription Error",
        description: `Failed to transcribe audio: ${error.message}. Please try again.`,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const answeredCount = Array.isArray(answers)
    ? answers.filter((answer) => answer && answer.trim() !== "").length
    : Object.values(answers).filter((answer) => answer && answer.trim() !== "")
        .length;
  const totalQuestions = questions.length;
  const minimumRequired = 4;

  const parseQuestion = (question) => {
    const parts = question.split("**").filter((part) => part.trim());
    if (parts.length >= 2) {
      return {
        mainQuestion: parts[0],
        subQuestion: parts[1],
      };
    }
    return {
      mainQuestion: question,
      subQuestion: "",
    };
  };

  if (isQuestionsLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-black"></div>
      </div>
    );
  }

  if (!hasGeneratedQuestions) {
    return (
      <div className="flex flex-col items-center justify-center p-8 bg-gray-50 rounded-lg border-2 border-gray-200">
        <MessageSquare className="h-12 w-12 text-gray-400 mb-4" />
        <p className="text-lg text-gray-600 text-center">
          Please submit your topics first to generate interview questions.
        </p>
      </div>
    );
  }

  return (
    <div className=" mx-auto max-w-4xl flex flex-col items-center">
      <div className="w-full mb-8">
        <div className="bg-white border-4 border-black p-4 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] rounded-lg">
          <div className="flex flex-col sm:flex-row justify-between items-center gap-3">
            <div className="flex items-center">
              <div className="bg-[#f8bf5d] w-2 h-12 mr-3 border-r-2 border-black"></div>
              <p className="font-bold text-lg">
                Please answer at least {minimumRequired} questions
              </p>
            </div>
            <div className="flex items-center gap-2 bg-black text-white px-4 py-2 rounded-md">
              <span className="font-bold text-2xl">{answeredCount}</span>
              <span className="text-gray-400">/</span>
              <span className="text-gray-400">{totalQuestions}</span>
              <span className="ml-2 text-sm">answered</span>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8 w-full">
        {visibleQuestions.map((question, index) => {
          const isAnswered = Boolean(answers[index]);
          const isActiveRecording =
            isRecording && activeQuestionIndex === index;
          const { mainQuestion, subQuestion } = parseQuestion(question);

          return (
            <Card
              key={index}
              className="bg-white border-4 rounded-2xl border-black shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] relative transform transition-all duration-200 hover:translate-y-1 hover:shadow-[6px_6px_0px_0px_rgba(0,0,0,1)] flex flex-col min-h-[200px]" // Added flex and min-height
            >
              {isAnswered && (
                <div className="inline-flex items-center justify-center w-6 h-6 border-2 border-black rounded-sm bg-green-500 absolute top-2 right-2">
                  <Check className="w-4 h-4 text-white" />
                </div>
              )}

              <CardContent className="p-6 flex flex-col flex-1 justify-between space-y-2">
                <div className="space-y-1">
                  <h2 className="text-black font-bold text-xl">
                    {mainQuestion}
                  </h2>
                  <p className="text-gray-600 text-sm">{subQuestion}</p>
                </div>

                {isTyping && activeQuestionIndex === index ? (
                  <div className="space-y-2">
                    <Textarea
                      value={textAnswer}
                      onChange={(e) => setTextAnswer(e.target.value)}
                      placeholder="Type your answer here..."
                      data-question={activeQuestionIndex}
                      className="w-full min-h-[100px] border-2 border-black focus:border-[#f8bf5d] focus:ring-0"
                    />
                    <div className="flex justify-end gap-2">
                      <Button
                        onClick={() => toggleInputMethod(index)}
                        variant="outline"
                        className="text-gray-500"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => handleTextSubmit(index)}
                        className="bg-[#f8bf5d] hover:bg-[#fba40d] text-black"
                      >
                        Submit Answer
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="space-y-2">
                    <Button
                      onClick={() =>
                        isActiveRecording
                          ? stopRecording()
                          : startRecording(index)
                      }
                      disabled={
                        isLoading || (isRecording && !isActiveRecording)
                      }
                      className={`w-full px-4 py-2 font-bold ${
                        isActiveRecording
                          ? "bg-red-500 hover:bg-red-600"
                          : isAnswered
                          ? "bg-gray-200 hover:bg-gray-300"
                          : "bg-[#f8bf5d] hover:bg-[#fba40d]"
                      } text-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]`}
                    >
                      <Mic className="mr-2 h-4 w-4" />
                      {isActiveRecording
                        ? "Stop Recording"
                        : isAnswered
                        ? "Record your answer again"
                        : "Record your answer again"}
                    </Button>
                    <button
                      onClick={() => toggleInputMethod(index)}
                      className="w-full text-sm pt-1 text-gray-500 hover:text-gray-700 underline mt-2 flex items-center justify-center gap-1"
                    >
                      Type your answer
                    </button>
                  </div>
                )}
              </CardContent>
            </Card>
          );
        })}
      </div>
      {questions.length > initialQuestionsCount && (
        <Button
          onClick={() => setShowAllQuestions(!showAllQuestions)}
          className="max-w-screen-xl bg-white border-4 border-black text-black font-bold shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:translate-y-1 hover:shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] transition-all duration-200"
        >
          {showAllQuestions ? (
            <>
              <ChevronUp className="mr-2 h-4 w-4" />
              Show Less
            </>
          ) : (
            <>
              <ChevronDown className="mr-2 h-4 w-4" />
              Show More Questions
            </>
          )}
        </Button>
      )}
      {answeredCount >= minimumRequired &&
        currentQuestionIndex === questions.length - 1 && (
          <Button
            onClick={onComplete}
            className="w-full mt-4 bg-green-500 hover:bg-green-600 text-white font-bold shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:translate-y-1 hover:shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] transition-all duration-200"
          >
            Complete Interview
          </Button>
        )}
    </div>
  );
};

export default SpeechToTextApp;
