import React, { useRef, useEffect } from "react";
import { motion, useAnimationFrame } from "framer-motion";

const PostExamples = () => {
  const examples = [
    "screenshot3.png",
    "screenshot4.png",
    "screenshot5.png",
    "screenshot2.png",
    "screenshot6.png",
    "screenshot1.png",
  ];

  const containerRef = useRef(null);
  const xRef = useRef(0);
  const SCROLL_SPEED = 0.5;

  useAnimationFrame(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.scrollWidth / 2;
      xRef.current = (xRef.current - SCROLL_SPEED) % containerWidth;
      containerRef.current.style.transform = `translateX(${xRef.current}px)`;
    }
  });

  return (
    <div className="w-full mb-8 relative overflow-hidden">
      <div className="absolute left-0 top-0 bottom-0 w-24 bg-gradient-to-r from-white to-transparent z-10" />

      <div className="overflow-hidden whitespace-nowrap py-4">
        <motion.div ref={containerRef} className="inline-flex gap-4">
          {[...examples, ...examples].map((image, index) => (
            <div
              key={index}
              className="w-64 h-80 inline-block flex-shrink-0 px-2"
            >
              <div className="h-full rounded-lg border-4 border-black overflow-hidden shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] group">
                <img
                  src={image}
                  alt={`LinkedIn post example ${index + 1}`}
                  className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                />
              </div>
            </div>
          ))}
        </motion.div>
      </div>

      <div className="absolute right-0 top-0 bottom-0 w-24 bg-gradient-to-l from-white to-transparent z-10" />
    </div>
  );
};

export default PostExamples;
