import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";
import { MessageSquare } from "lucide-react";
import { ScrollArea } from "src/components/ui/scroll-area";

const InterviewQA = ({ questions, answers }) => {
  // If we have answers but no questions, format them as standalone responses
  const hasQuestionsAndAnswers = questions?.length > 0 && answers?.length > 0;
  const hasOnlyAnswers =
    (!questions || questions.length === 0) && answers?.length > 0;

  const renderContent = () => {
    if (hasQuestionsAndAnswers) {
      return questions.map((question, index) => (
        <div
          key={index}
          className="mb-6 p-4 rounded-lg border-2 border-gray-200 hover:border-black transition-all duration-200"
        >
          <div className="flex items-start justify-between mb-3">
            <div className="flex items-center gap-2">
              <span className="text-sm font-medium text-gray-500">
                Q{index + 1}:
              </span>
              <h4 className="font-semibold text-lg">{question.question}</h4>
            </div>
            <span className="text-xs text-gray-500">
              {new Date(question.timestamp).toLocaleDateString()}
            </span>
          </div>

          <div className="ml-6 pl-4 border-l-2 border-[#f8bf5d]">
            <p className="text-gray-700 whitespace-pre-wrap">
              {answers[index] || (
                <span className="text-gray-400 italic">No answer provided</span>
              )}
            </p>
          </div>
        </div>
      ));
    }

    if (hasOnlyAnswers) {
      return answers.map((answer, index) => (
        <div
          key={index}
          className="mb-6 p-4 rounded-lg border-2 border-gray-200 hover:border-black transition-all duration-200"
        >
          <div className="flex items-start gap-2">
            <span className="text-sm font-medium text-gray-500">
              Response {index + 1}:
            </span>
            <p className="text-gray-700 whitespace-pre-wrap flex-1">
              {answer || (
                <span className="text-gray-400 italic">Empty response</span>
              )}
            </p>
          </div>
        </div>
      ));
    }

    return (
      <div className="text-center py-8 text-gray-500 italic">
        No interview responses available
      </div>
    );
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <MessageSquare className="w-5 h-5" />
          {hasQuestionsAndAnswers
            ? "Interview Questions & Answers"
            : "Interview Responses"}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[400px] pr-4">{renderContent()}</ScrollArea>
      </CardContent>
    </Card>
  );
};

export default InterviewQA;
