import React, { useState } from "react";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { Info } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";

const FormField = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  required,
  type = "text",
  tooltipContent,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  // Handle both touch and mouse events
  const handleTooltipInteraction = (e) => {
    e.preventDefault();
    setIsTooltipOpen(!isTooltipOpen);
  };

  return (
    <div className="space-y-2 mb-6">
      <div className="flex items-center space-x-2">
        <label
          htmlFor={name}
          className="text-xl font-bold text-black uppercase"
        >
          {label} {required && <span className="text-red-500">*</span>}
        </label>
        {tooltipContent && (
          <TooltipProvider>
            <Tooltip open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
              <TooltipTrigger
                onClick={handleTooltipInteraction}
                onMouseEnter={() => setIsTooltipOpen(true)}
                onMouseLeave={() => setIsTooltipOpen(false)}
                onTouchStart={handleTooltipInteraction}
                className="cursor-help touch-none"
              >
                <Info className="h-5 w-5 text-gray-500" />
              </TooltipTrigger>
              <TooltipContent className="bg-black max-w-80 text-white p-2 whitespace-pre-line">
                <p>{tooltipContent}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
      {type === "textarea" ? (
        <Textarea
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className="w-full border-4 border-black focus:border-[#f8bf5d] focus:ring-0 text-lg p-2"
          required={required}
        />
      ) : (
        <Input
          type={type}
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className="w-full border-4 border-black focus:border-[#f8bf5d] focus:ring-0 text-lg p-2"
          required={required}
        />
      )}
    </div>
  );
};

export default FormField;
