// src/lib/formStates.js

export const initialFormState = {
  website: "",
  email: "",
  industry: "",
  icp: "",
  linkedinProfile: "",
  otherProfiles: "",
  topicsToTalkAbout: "",
  websiteInfo: null,
  isLoadingWebsiteInfo: false,
  interviewAnswers: {},
  last_post_1: "",
  last_post_2: "",
  last_post_3: "",
  last_post_4: "",
  last_post_5: "",
  trelloBoardId: null,
  trelloTodoListId: null,
  trelloFormListId: null,
  files: {
    teamPhotos: [],
    brandManual: null,
    uploadedFiles: [],
  },
  contentIdeas: [],
  selectedIdeas: [],
  generatedPosts: [],
  trelloCardUrls: [],
  isSubmitSuccessful: false,
};

// You can add more form-related constants here
export const steps = ["Brand Information", "Upload Assets", "Interview"];
