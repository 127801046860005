import React, { useState } from "react";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameDay,
  parseISO,
} from "date-fns";
import {
  ChevronLeft,
  ChevronRight,
  Plus,
  Calendar as CalendarIcon,
  Image,
  X,
} from "lucide-react";
import { Button } from "src/components/ui/button";
import { Dialog, DialogContent } from "src/components/ui/dialog";
import { Card } from "src/components/ui/card";
import { Badge } from "src/components/ui/badge";
import { Input } from "src/components/ui/input";
import { Textarea } from "src/components/ui/textarea";
import { Label } from "src/components/ui/label";
import { cn } from "src/lib/utils";

// Mock data with more detailed content
const mockPosts = [
  {
    id: 1,
    title: "LinkedIn Growth Strategy 2025",
    content:
      "🚀 Ready to skyrocket your LinkedIn presence?\n\nJoin our pilot program and get 4 thought leadership LinkedIn posts for your personal profile, completely FREE!\n\nWe're helping busy professionals maintain a strong LinkedIn presence without the hassle of content creation.\n\n🎯 What you'll get:\n- 4 customized posts monthly\n- Personal brand alignment\n- Professional editing\n- Content calendar\n\nSpots are limited! DM me for details.\n\n#LinkedInGrowth #ContentStrategy #PersonalBranding",
    date: "2024-12-10",
    status: "scheduled",
    images: [
      "/api/placeholder/800/400",
      "/api/placeholder/800/400",
      "/api/placeholder/800/400",
    ],
    tags: ["LinkedInGrowth", "ContentStrategy", "PersonalBranding"],
    engagement: {
      likes: 87,
      comments: 24,
      reposts: 12,
    },
  },
  {
    id: 2,
    title: "Content Marketing Success",
    content:
      "Do you want to build LinkedIn presence but don't have time to create content?",
    date: "2024-12-15",
    status: "draft",
    image: "/api/placeholder/800/400",
    tags: ["Tips", "Content"],
  },
];

const PostPreview = ({ post }) => {
  const statusColors = {
    draft: "bg-gray-100 text-gray-700 border-gray-200",
    scheduled: "bg-green-100 text-green-700 border-green-200",
    published: "bg-blue-100 text-blue-700 border-blue-200",
  };

  const getRelativeTime = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInHours = Math.floor((now - date) / (1000 * 60 * 60));

    if (diffInHours < 1) return "Just now";
    if (diffInHours < 24) return `${diffInHours}h`;
    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) return `${diffInDays}d`;
    return format(date, "MMM d");
  };

  return (
    <div className="max-w-[550px] mx-auto">
      {/* LinkedIn UI Container */}
      <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
        {/* Post Header */}
        <div className="p-4 flex items-start gap-3">
          {/* Profile Picture */}
          <div className="w-12 h-12 rounded-full bg-gray-200 overflow-hidden">
            <img
              src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8aGVhZHNob3R8ZW58MHx8MHx8fDA%3D"
              alt="John Bolt"
              className="w-full h-full object-cover"
            />
          </div>

          {/* User Info & Post Meta */}
          <div className="flex-1 min-w-0">
            <div className="flex items-center justify-between">
              <div>
                <h4 className="font-semibold text-[14px] text-gray-900">
                  John Bolt
                </h4>
                <p className="text-[12px] text-gray-500">
                  Growth Marketing | Content Strategy | LinkedIn Expert
                </p>
                <div className="flex items-center gap-1 text-[12px] text-gray-500 mt-0.5">
                  <span>{getRelativeTime(post.date)}</span>
                  <span>•</span>
                  <div className="w-4 h-4">🌍</div>
                </div>
              </div>

              {/* Post Status */}
              <Badge className={cn("shrink-0", statusColors[post.status])}>
                {post.status}
              </Badge>
            </div>
          </div>
        </div>

        {/* Post Content */}
        <div className="px-4 pb-3">
          <div className="whitespace-pre-wrap text-[14px] text-gray-900 mb-2">
            {post.content}
          </div>

          {/* Tags */}
          <div className="flex flex-wrap gap-1 mt-2">
            {post.tags?.map((tag) => (
              <span key={tag} className="text-blue-600 text-[14px]">
                #{tag.replace(/\s+/g, "")}
              </span>
            ))}
          </div>
        </div>

        {/* Images */}
        {post.images?.length > 0 && (
          <div
            className={cn(
              "grid gap-0.5 mb-3",
              post.images.length === 1
                ? "grid-cols-1"
                : post.images.length === 2
                ? "grid-cols-2"
                : post.images.length === 3
                ? "grid-cols-2"
                : "grid-cols-2"
            )}
          >
            {post.images.slice(0, 4).map((image, index) => (
              <div
                key={index}
                className={cn(
                  "relative",
                  post.images.length === 1
                    ? "col-span-2"
                    : post.images.length === 3 && index === 0
                    ? "col-span-2"
                    : ""
                )}
              >
                <img
                  src={image}
                  alt={`${post.title} ${index + 1}`}
                  className="w-full h-full object-cover"
                  style={{
                    aspectRatio: post.images.length === 1 ? "16/9" : "4/3",
                  }}
                />
              </div>
            ))}
            {post.images.length > 4 && (
              <div className="absolute bottom-2 right-2 bg-black/50 text-white px-2 py-1 rounded-full text-sm">
                +{post.images.length - 4}
              </div>
            )}
          </div>
        )}

        {/* Engagement Metrics */}
        <div className="px-4 py-2 border-t flex items-center justify-between text-[12px] text-gray-500">
          <div className="flex items-center gap-1">
            <span>👍 87</span>
            <span>•</span>
            <span>💡 14</span>
          </div>
          <div>24 comments • 12 reposts</div>
        </div>

        {/* Action Buttons */}
        <div className="px-2 py-1 border-t flex justify-between">
          <button className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 rounded-lg text-gray-500">
            <div className="w-5 h-5">👍</div>
            <span className="text-[14px]">Like</span>
          </button>
          <button className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 rounded-lg text-gray-500">
            <div className="w-5 h-5">💬</div>
            <span className="text-[14px]">Comment</span>
          </button>
          <button className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 rounded-lg text-gray-500">
            <div className="w-5 h-5">🔄</div>
            <span className="text-[14px]">Repost</span>
          </button>
          <button className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 rounded-lg text-gray-500">
            <div className="w-5 h-5">📤</div>
            <span className="text-[14px]">Send</span>
          </button>
        </div>
      </div>
    </div>
  );
};

const PostEditor = ({ post, onSave, onCancel }) => {
  const [editedPost, setEditedPost] = useState(post);
  const [imagePreview, setImagePreview] = useState(post?.image || null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedPost((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageRemove = () => {
    setImagePreview(null);
    setEditedPost((prev) => ({
      ...prev,
      image: null,
    }));
  };

  return (
    <div className="flex h-[700px] gap-6 p-6">
      {/* Preview Panel */}
      <div className="w-1/2 flex flex-col">
        <h3 className="font-semibold mb-4 text-lg">Preview</h3>
        <div className="flex-1 bg-gray-50 rounded-lg p-4 overflow-y-auto">
          <PostPreview post={editedPost} />
        </div>
      </div>

      {/* Editor Panel */}
      <div className="w-1/2 flex flex-col">
        <h3 className="font-semibold mb-4 text-lg">Edit Post</h3>
        <div className="flex-1 space-y-6 overflow-y-auto pr-4">
          <div className="space-y-2">
            <Label htmlFor="title">Post Title</Label>
            <Input
              id="title"
              name="title"
              value={editedPost.title}
              onChange={handleChange}
              placeholder="Enter post title"
              className="border-2"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="content">Content</Label>
            <Textarea
              id="content"
              name="content"
              value={editedPost.content}
              onChange={handleChange}
              placeholder="Write your post content..."
              className="min-h-[200px] border-2"
            />
          </div>

          <div className="space-y-2">
            <Label>Images</Label>
            <div className="space-y-4">
              {/* Image Gallery */}
              {editedPost.images?.length > 0 && (
                <div className="grid grid-cols-2 gap-4">
                  {editedPost.images.map((img, index) => (
                    <div key={index} className="relative group">
                      <img
                        src={img}
                        alt={`Upload ${index + 1}`}
                        className="w-full h-32 object-cover rounded-lg"
                      />
                      <Button
                        size="icon"
                        variant="destructive"
                        className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                        onClick={() => {
                          setEditedPost((prev) => ({
                            ...prev,
                            images: prev.images.filter((_, i) => i !== index),
                          }));
                        }}
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    </div>
                  ))}
                </div>
              )}

              {/* Upload Area */}
              <div
                className="border-2 border-dashed rounded-lg p-4 hover:bg-gray-50 transition-colors cursor-pointer"
                onClick={() => document.getElementById("imageInput").click()}
              >
                <div className="flex flex-col items-center justify-center py-4 text-gray-500">
                  <Image className="h-8 w-8 mb-2" />
                  <span>Click to upload images</span>
                  <span className="text-sm text-gray-400 mt-1">
                    Supports: JPG, PNG, GIF (max 5MB each)
                  </span>
                </div>
                <input
                  id="imageInput"
                  type="file"
                  accept="image/*"
                  multiple
                  className="hidden"
                  onChange={(e) => {
                    const files = Array.from(e.target.files);
                    files.forEach((file) => {
                      if (file.size > 5 * 1024 * 1024) {
                        alert(`File ${file.name} is larger than 5MB`);
                        return;
                      }
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        setEditedPost((prev) => ({
                          ...prev,
                          images: [...(prev.images || []), reader.result],
                        }));
                      };
                      reader.readAsDataURL(file);
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="space-y-2">
            <Label htmlFor="date">Scheduled Date</Label>
            <div className="flex gap-2 items-center">
              <Input
                id="date"
                name="date"
                type="date"
                value={editedPost.date}
                onChange={handleChange}
                className="border-2"
              />
              <CalendarIcon className="h-5 w-5 text-gray-500" />
            </div>
          </div>

          <div className="space-y-2">
            <Label>Tags</Label>
            <div className="flex flex-wrap gap-2">
              {editedPost.tags?.map((tag) => (
                <Badge key={tag} variant="secondary" className="text-sm">
                  {tag}
                  <Button
                    size="icon"
                    variant="ghost"
                    className="h-4 w-4 ml-1 hover:bg-transparent"
                    onClick={() => {
                      setEditedPost((prev) => ({
                        ...prev,
                        tags: prev.tags.filter((t) => t !== tag),
                      }));
                    }}
                  >
                    <X className="h-3 w-3" />
                  </Button>
                </Badge>
              ))}
              <Button
                variant="outline"
                size="sm"
                className="text-sm"
                onClick={() => {
                  const tag = prompt("Enter new tag:");
                  if (tag) {
                    setEditedPost((prev) => ({
                      ...prev,
                      tags: [...(prev.tags || []), tag],
                    }));
                  }
                }}
              >
                Add Tag
              </Button>
            </div>
          </div>
        </div>

        <div className="pt-6 flex justify-end gap-2 border-t mt-6">
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={() => onSave(editedPost)}>Save Changes</Button>
        </div>
      </div>
    </div>
  );
};

const ContentCalendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date(2024, 11, 1));
  const [selectedPost, setSelectedPost] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [posts, setPosts] = useState(mockPosts);
  const [hoveredDay, setHoveredDay] = useState(null);

  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(currentDate);
  const daysInMonth = eachDayOfInterval({ start: monthStart, end: monthEnd });

  const previousMonth = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)));
  };

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)));
  };

  const getPostsForDay = (day) => {
    return posts.filter((post) => isSameDay(parseISO(post.date), day));
  };

  const handlePostClick = (post) => {
    setSelectedPost(post);
    setIsEditModalOpen(true);
  };

  const handleSavePost = (editedPost) => {
    setPosts((prevPosts) =>
      prevPosts.map((post) => (post.id === editedPost.id ? editedPost : post))
    );
    setIsEditModalOpen(false);
  };

  const handleAddPost = (day) => {
    const newPost = {
      id: Date.now(),
      title: "New Post",
      content: "",
      date: format(day, "yyyy-MM-dd"),
      status: "draft",
      tags: [],
    };
    setPosts((prev) => [...prev, newPost]);
    setSelectedPost(newPost);
    setIsEditModalOpen(true);
  };

  return (
    <div className="container mx-auto p-6">
      {/* Calendar Header */}
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center gap-4">
          <h2 className="text-3xl font-bold">
            {format(currentDate, "MMMM yyyy")}
          </h2>
          <div className="flex gap-2">
            <Button variant="outline" size="icon" onClick={previousMonth}>
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <Button variant="outline" size="icon" onClick={nextMonth}>
              <ChevronRight className="h-4 w-4" />
            </Button>
          </div>
        </div>
        <Button
          className="bg-[#f8bf5d] hover:bg-[#fba40d] text-black font-semibold"
          onClick={() => handleAddPost(new Date())}
        >
          <Plus className="h-4 w-4 mr-2" />
          Create Post
        </Button>
      </div>

      {/* Calendar Grid */}
      <div className="grid grid-cols-7 gap-4">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div
            key={day}
            className="font-semibold text-center py-2 text-gray-600"
          >
            {day}
          </div>
        ))}

        {daysInMonth.map((day) => {
          const postsForDay = getPostsForDay(day);
          const isHovered = hoveredDay && isSameDay(hoveredDay, day);

          return (
            <div
              key={day.toString()}
              className={cn(
                "min-h-[150px] rounded-lg border-2 transition-all duration-200",
                "hover:border-[#f8bf5d] hover:shadow-lg relative group",
                isSameDay(day, new Date()) && "bg-yellow-50 border-yellow-200",
                !postsForDay.length && "hover:bg-gray-50"
              )}
              onMouseEnter={() => setHoveredDay(day)}
              onMouseLeave={() => setHoveredDay(null)}
            >
              <div className="p-2">
                <div className="font-medium mb-2">{format(day, "d")}</div>
                <div className="space-y-2">
                  {postsForDay.map((post) => (
                    <Card
                      key={post.id}
                      className="cursor-pointer hover:shadow-md transition-shadow overflow-hidden"
                      onClick={() => handlePostClick(post)}
                    >
                      {post.image && (
                        <img
                          src={post.image}
                          alt={post.title}
                          className="w-full h-20 object-cover"
                        />
                      )}
                      <div className="p-2">
                        <h4 className="font-semibold text-sm truncate">
                          {post.title}
                        </h4>
                        <Badge
                          className={cn(
                            "mt-1 text-xs",
                            post.status === "scheduled" &&
                              "bg-green-100 text-green-700",
                            post.status === "draft" &&
                              "bg-gray-100 text-gray-700",
                            post.status === "published" &&
                              "bg-blue-100 text-blue-700"
                          )}
                        >
                          {post.status}
                        </Badge>
                      </div>
                    </Card>
                  ))}
                </div>

                {/* Add Post Button - Always visible on hover */}
                {isHovered && (
                  <Button
                    variant="ghost"
                    className="absolute bottom-2 right-2 bg-[#f8bf5d] hover:bg-[#fba40d] text-black opacity-0 group-hover:opacity-100 transition-opacity"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddPost(day);
                    }}
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Post
                  </Button>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {/* Edit Modal */}
      <Dialog open={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
        <DialogContent className="max-w-[1000px] p-0">
          {selectedPost && (
            <PostEditor
              post={selectedPost}
              onSave={handleSavePost}
              onCancel={() => setIsEditModalOpen(false)}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ContentCalendar;
