import React, { useState, useCallback } from "react";
import { Button } from "./ui/button";
import { Alert, AlertDescription } from "./ui/alert";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "./ui/tooltip";

import { Upload, Loader2, Info, ExternalLink, X, FileIcon } from "lucide-react";

const FileUpload = ({
  label,
  accept,
  multiple,
  tooltipContent,
  onRemove,
  type,
  boardId,
  onUploadProgress,
  onUploadComplete,
  onUploadError,
  uploadedFiles = [],
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState({});
  const [error, setError] = React.useState(null);
  const TRELLO_API_KEY = "c987a9e5db3aa4875ba07636997b8263";
  const TRELLO_TOKEN =
    "ATTAdef27c382d566a02e494f8911d0889e874f0ac61115f2043b79ec8099dc85776359ED2E6";
  const handleTooltipInteraction = (e) => {
    e.preventDefault();
    setIsTooltipOpen(!isTooltipOpen);
  };

  const handleFileUpload = useCallback(
    async (event) => {
      const selectedFiles = Array.from(event.target.files);
      if (!selectedFiles.length) return;

      setIsUploading(true);
      setError(null);

      for (const file of selectedFiles) {
        if (file.size > 100 * 1024 * 1024) {
          setError(`File ${file.name} exceeds 100MB limit`);
          continue;
        }

        try {
          const cardParams = new URLSearchParams({
            key: TRELLO_API_KEY,
            token: TRELLO_TOKEN,
            idList: boardId,
            name: `Asset Upload: ${file.name}`,
            desc: `Uploaded asset: ${
              file.name
            }\nType: ${type}\nUploaded on: ${new Date().toLocaleString()}`,
          });

          const cardResponse = await fetch(
            `https://api.trello.com/1/cards?${cardParams}`,
            { method: "POST" }
          );

          if (!cardResponse.ok) {
            throw new Error(
              `Failed to create Trello card: ${cardResponse.statusText}`
            );
          }

          const card = await cardResponse.json();

          const formData = new FormData();
          formData.append("file", file);
          formData.append("key", TRELLO_API_KEY);
          formData.append("token", TRELLO_TOKEN);

          const xhr = new XMLHttpRequest();
          xhr.upload.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
              const percentCompleted = Math.round(
                (event.loaded * 100) / event.total
              );
              setUploadProgress((prev) => ({
                ...prev,
                [file.name]: percentCompleted,
              }));
              if (onUploadProgress) {
                onUploadProgress(file.name, percentCompleted);
              }
            }
          });

          const uploadPromise = new Promise((resolve, reject) => {
            xhr.addEventListener("load", () => {
              if (xhr.status >= 200 && xhr.status < 300) {
                resolve(JSON.parse(xhr.response));
              } else {
                reject(new Error(`Upload failed with status ${xhr.status}`));
              }
            });

            xhr.addEventListener("error", () => {
              reject(new Error("Upload failed"));
            });
          });

          xhr.open(
            "POST",
            `https://api.trello.com/1/cards/${card.id}/attachments`
          );
          xhr.send(formData);

          const attachmentResponse = await uploadPromise;

          // Add the new file to the uploadedFiles array
          if (onUploadComplete) {
            onUploadComplete(file.name, {
              name: file.name,
              size: file.size,
              cardId: card.id,
              attachmentId: attachmentResponse.id,
              url: attachmentResponse.url,
              cardUrl: card.shortUrl,
              type: file.type,
              uploadedAt: new Date().toISOString(),
            });
          }
        } catch (err) {
          console.error(`Error uploading ${file.name}:`, err);
          setError(`Failed to upload ${file.name}: ${err.message}`);
          if (onUploadError) {
            onUploadError(file.name, err.message);
          }
        }
      }

      setIsUploading(false);
      event.target.value = ""; // Reset input
    },
    [boardId, type, onUploadProgress, onUploadComplete, onUploadError]
  );

  return (
    <div className="space-y-4 mb-6">
      <div className="flex items-center space-x-2">
        <label className="text-xl font-bold text-black uppercase">
          {label}
        </label>
        {tooltipContent && (
          <TooltipProvider>
            <Tooltip open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
              <TooltipTrigger
                onClick={handleTooltipInteraction}
                onMouseEnter={() => setIsTooltipOpen(true)}
                onMouseLeave={() => setIsTooltipOpen(false)}
                onTouchStart={handleTooltipInteraction}
                className="cursor-help touch-none"
              >
                <Info className="h-5 w-5 text-gray-500" />
              </TooltipTrigger>
              <TooltipContent
                side="right"
                className="bg-black text-white p-2 max-w-xs z-50"
              >
                <p>{tooltipContent}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>

      {error && (
        <Alert variant="destructive" className="mb-4">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <div className="flex  flex-col items-center justify-center w-full ">
        <label className="flex flex-col items-center justify-center w-full h-64 border-4 border-black border-dashed cursor-pointer bg-white hover:bg-gray-50 transition-colors duration-300">
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <Upload className="w-16 h-16 mb-4 text-black" />
            <p className="mb-2 text-xl font-bold text-black uppercase">
              Click to upload {multiple ? "files" : "a file"}
            </p>
            <p className="text-sm text-gray-500">{accept} </p>
          </div>
          <input
            type="file"
            className="hidden"
            onChange={handleFileUpload}
            accept={accept}
            multiple={multiple}
            disabled={isUploading}
          />
        </label>
      </div>

      {/* Uploaded Files List */}
      {(uploadedFiles.length > 0 || Object.keys(uploadProgress).length > 0) && (
        <div className="mt-4">
          <h4 className="font-bold text-xl mb-2 uppercase">Uploaded files:</h4>
          <div className="space-y-2">
            {uploadedFiles.map((file) => (
              <div
                key={file.attachmentId}
                className="flex items-center justify-between bg-white border-2 border-black p-3 rounded-lg"
              >
                <div className="flex items-center space-x-3">
                  {file.type.startsWith("image/") ? (
                    <div className="relative w-16 h-16">
                      <img
                        src={`/api/get-trello-image?cardId=${file.cardId}`}
                        alt={file.name}
                        className="w-full h-full object-cover rounded border-2 border-black"
                      />
                    </div>
                  ) : (
                    <div className="w-16 h-16 flex items-center justify-center bg-gray-100 rounded border-2 border-black">
                      <FileIcon className="w-8 h-8 text-gray-500" />
                    </div>
                  )}
                  <div>
                    <p className="font-medium truncate max-w-xs">{file.name}</p>
                    <p className="text-sm text-gray-500">
                      {(file.size / (1024 * 1024)).toFixed(2)} MB
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <Button
                    onClick={() =>
                      window.open(
                        `/api/get-trello-image?cardId=${file.cardId}`,
                        "_blank"
                      )
                    }
                    variant="outline"
                    size="sm"
                    className="text-blue-500 hover:text-blue-700"
                  >
                    <ExternalLink className="h-4 w-4" />
                  </Button>
                  <Button
                    onClick={() => onRemove(file)}
                    variant="outline"
                    size="sm"
                    className="text-red-500 hover:text-red-700"
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {isUploading && (
        <div className="flex items-center justify-center mt-4">
          <Loader2 className="h-6 w-6 animate-spin mr-2" />
          <span>Uploading...</span>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
