import React, { useState, useEffect, useCallback } from "react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { X, Plus, MessageSquare, Loader2, Info } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Badge } from "./ui/badge";
import { Alert, AlertDescription } from "./ui/alert";
import { motion, AnimatePresence } from "framer-motion";
import { useToast } from "./ui/use-toast";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { useSearchParams } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://jhknbuxutiqdvlxnvawv.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Impoa25idXh1dGlxZHZseG52YXd2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjcyOTgxMDYsImV4cCI6MjA0Mjg3NDEwNn0.l3K1xIyN2uZHUrXvjSGr_UXW2s2n9dQvXz8kB_x4K1E"
);

// Base questions that will always be included
const BASE_QUESTIONS = [
  {
    question: "What is your story? How has your professional journey started?",
    isBase: true,
  },
  {
    question: "What makes you better than your competitors? The one thing?",
    isBase: true,
  },
  {
    question: "What is your target audience on LinkedIn?",
    isBase: true,
  },
];

// Standard question templates for each topic
const STANDARD_QUESTIONS = [
  (topic) => `What is your controversial opinion about "${topic}"?`,
  (topic) => `What 5 pieces of advice would you give about "${topic}"?`,
  (topic) => `What is "${topic}" going to look like in the near future?`,
];

const TopicBasedInterview = ({ onTopicsGenerated, initialTopics = [] }) => {
  const [searchParams] = useSearchParams();
  const formId = searchParams.get("formId");

  const [topics, setTopics] = useState(initialTopics);
  const [currentTopic, setCurrentTopic] = useState("");
  const [error, setError] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [allQuestions, setAllQuestions] = useState([]);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [questionsGenerated, setQuestionsGenerated] = useState(false);

  const { toast } = useToast();

  // Initialize component with existing data
  useEffect(() => {
    const initializeTopicsAndQuestions = async () => {
      if (!hasInitialized && initialTopics.length > 0) {
        setTopics(initialTopics);

        if (formId) {
          try {
            const { data, error } = await supabase
              .from("form_submissions")
              .select("interview_questions")
              .eq("id", formId)
              .single();

            if (!error && data?.interview_questions) {
              // Initialize with existing questions
              const existingQuestions = data.interview_questions;
              const baseQuestionsToAdd = BASE_QUESTIONS.filter(
                (baseQ) =>
                  !existingQuestions.some((q) => q.question === baseQ.question)
              ).map((q) => ({
                ...q,
                timestamp: new Date().toISOString(),
              }));

              const updatedQuestions = [
                ...baseQuestionsToAdd,
                ...existingQuestions,
              ];
              setAllQuestions(updatedQuestions);
              onTopicsGenerated(updatedQuestions.map((q) => q.question));
              setQuestionsGenerated(true);
            } else {
              // Initialize with base questions only
              const initialQuestions = BASE_QUESTIONS.map((q) => ({
                ...q,
                timestamp: new Date().toISOString(),
              }));
              setAllQuestions(initialQuestions);
              onTopicsGenerated(initialQuestions.map((q) => q.question));
            }
          } catch (error) {
            console.error("Error loading existing questions:", error);
            toast({
              title: "Error",
              description: "Failed to load existing questions",
              variant: "destructive",
            });
          }
        }
        setHasInitialized(true);
      }
    };

    initializeTopicsAndQuestions();
  }, [initialTopics, hasInitialized, formId, onTopicsGenerated, toast]);

  // Update topics in database
  const updateTopicsInDatabase = async (updatedTopics) => {
    if (!formId) return;

    try {
      setIsUpdating(true);
      const { error: updateError } = await supabase
        .from("form_submissions")
        .update({
          topics_to_talk_about: updatedTopics.join(", "),
        })
        .eq("id", formId);

      if (updateError) throw updateError;
    } catch (error) {
      console.error("Error updating topics in database:", error);
      throw new Error("Failed to update topics in database");
    } finally {
      setIsUpdating(false);
    }
  };

  // Generate questions for all topics
  const generateQuestionsForTopics = async (topicsToGenerate) => {
    try {
      // Call the API to generate AI questions
      const response = await fetch("/api/generate-questions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ topics: topicsToGenerate }),
      });

      if (!response.ok) {
        throw new Error("Failed to generate AI questions");
      }

      const data = await response.json();
      let allNewQuestions = [];

      // Process each topic
      topicsToGenerate.forEach((topic) => {
        // Get AI-generated questions
        const aiQuestions = data[topic] || [];

        // Get random standard questions
        const standardQuestions = [...STANDARD_QUESTIONS]
          .sort(() => 0.5 - Math.random())
          .slice(0, 2)
          .map((questionFn) => questionFn(topic));

        // Combine and format all questions for this topic
        const topicQuestions = [...standardQuestions, ...aiQuestions].map(
          (question) => ({
            question,
            timestamp: new Date().toISOString(),
            topic: topic,
          })
        );

        allNewQuestions = [...allNewQuestions, ...topicQuestions];
      });

      return allNewQuestions;
    } catch (error) {
      console.error("Error generating questions:", error);
      throw error;
    }
  };

  // Handle topic submission and question generation
  const handleTopicSubmit = async () => {
    if (topics.length === 0) {
      setError("Please add at least one topic before submitting");
      return;
    }

    setIsGenerating(true);
    setError("");

    try {
      // Generate questions for all topics
      const newQuestions = await generateQuestionsForTopics(topics);

      // Ensure base questions are included
      const existingBaseQuestions = allQuestions.filter((q) => q.isBase);
      const missingBaseQuestions = BASE_QUESTIONS.filter(
        (baseQ) =>
          !existingBaseQuestions.some((q) => q.question === baseQ.question)
      ).map((q) => ({
        ...q,
        timestamp: new Date().toISOString(),
      }));

      // Combine all questions
      const updatedQuestions = [
        ...missingBaseQuestions,
        ...existingBaseQuestions,
        ...newQuestions,
      ];

      // Update database if form ID exists
      if (formId) {
        await Promise.all([
          updateTopicsInDatabase(topics),
          supabase
            .from("form_submissions")
            .update({ interview_questions: updatedQuestions })
            .eq("id", formId),
        ]);
      }

      // Update local state
      setAllQuestions(updatedQuestions);
      onTopicsGenerated(updatedQuestions.map((q) => q.question));
      setQuestionsGenerated(true);

      toast({
        title: "Success",
        description: "Questions generated successfully",
        variant: "success",
      });
    } catch (error) {
      console.error("Error generating questions:", error);
      setError("Failed to generate questions. Please try again.");
      toast({
        title: "Error",
        description: "Failed to generate questions. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsGenerating(false);
    }
  };

  // Add new topic
  const handleAddTopic = () => {
    setError("");
    const trimmedTopic = currentTopic.trim();

    // Validate topic
    if (!trimmedTopic) {
      setError("Please enter a topic");
      return;
    }
    if (topics.includes(trimmedTopic)) {
      setError("This topic has already been added");
      return;
    }
    if (trimmedTopic.length > 100) {
      setError("Topic is too long (maximum 100 characters)");
      return;
    }

    // Add new topic to list
    setTopics((prev) => [...prev, trimmedTopic]);
    setCurrentTopic("");
    setQuestionsGenerated(false); // Reset questions generated flag
  };

  // Remove topic
  const handleRemoveTopic = async (indexToRemove) => {
    const newTopics = topics.filter((_, index) => index !== indexToRemove);

    try {
      setIsUpdating(true);
      await updateTopicsInDatabase(newTopics);
      setTopics(newTopics);
      setQuestionsGenerated(false); // Reset questions generated flag

      toast({
        title: "Topic Removed",
        description: "Topic removed successfully",
        variant: "info",
      });
    } catch (error) {
      console.error("Error removing topic:", error);
      toast({
        title: "Error",
        description: "Failed to remove topic. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsUpdating(false);
    }
  };

  // Handle enter key press
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !isGenerating && !isUpdating) {
      handleAddTopic();
    }
  };

  return (
    <Card className="border-4 border-black shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] bg-white relative transform transition-all duration-200 hover:translate-y-1 hover:shadow-[6px_6px_0px_0px_rgba(0,0,0,1)]">
      <CardHeader>
        <CardTitle className="flex items-center gap-2 text-xl font-bold">
          <MessageSquare className="w-6 h-6" />
          Topics You Want to Talk About
          <TooltipProvider>
            <Tooltip open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
              <TooltipTrigger className="cursor-help">
                <Info className="h-5 w-5 text-gray-500" />
              </TooltipTrigger>
              <TooltipContent className="bg-black max-w-80 text-white p-2 whitespace-pre-line">
                {`Please list the subjects or themes you'd like to cover in your LinkedIn posts.
                
Add all your topics and click "Submit Topics" to generate questions.

Examples:
- Growth strategies for startups
- Leadership in remote teams
- Industry trends and predictions`}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </CardTitle>
      </CardHeader>

      <CardContent className="space-y-6">
        <div className="space-y-2">
          <div className="flex flex-col sm:flex-row gap-2">
            <div className="flex-1">
              <Input
                value={currentTopic}
                onChange={(e) => setCurrentTopic(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Enter a topic you want to discuss on LinkedIn"
                className="w-full border-4 border-black focus:border-[#f8bf5d] focus:ring-0 text-lg p-2"
                disabled={isGenerating || isUpdating}
              />
            </div>
            <Button
              onClick={handleAddTopic}
              disabled={!currentTopic.trim() || isGenerating || isUpdating}
              className="bg-[#f8bf5d] hover:bg-[#fba40d] text-black border-4 border-black font-bold shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:translate-y-1 hover:shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] transition-all duration-200"
            >
              <Plus className="mr-2 h-5 w-5" />
              Add Topic
            </Button>
          </div>

          {error && (
            <Alert
              variant="destructive"
              className="border-2 border-red-500 bg-red-50"
            >
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
        </div>

        <AnimatePresence>
          {topics.length > 0 && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="space-y-4"
            >
              <div className="flex flex-wrap gap-2">
                {topics.map((topic, index) => (
                  <motion.div
                    key={topic}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{ duration: 0.2 }}
                  >
                    <Badge className="bg-[#f8bf5d] text-black px-4 py-2 text-base font-semibold flex items-center gap-2 border-2 border-black shadow-[2px_2px_0px_0px_rgba(0,0,0,1)]">
                      {topic}
                      <button
                        onClick={() => handleRemoveTopic(index)}
                        className="hover:bg-[#fba40d] rounded-full p-1 transition-colors"
                        aria-label="Remove topic"
                        disabled={isGenerating || isUpdating}
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </Badge>
                  </motion.div>
                ))}
              </div>

              <Button
                onClick={handleTopicSubmit}
                disabled={
                  isGenerating ||
                  isUpdating ||
                  topics.length === 0 ||
                  questionsGenerated
                }
                className="w-full bg-[#f8bf5d] hover:bg-[#fba40d] text-black border-4 border-black font-bold shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:translate-y-1 hover:shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] transition-all duration-200 mt-6 py-3 flex items-center justify-center gap-2"
              >
                {isGenerating ? (
                  <>
                    <Loader2 className="h-5 w-5 animate-spin" />
                    Generating Questions...
                  </>
                ) : questionsGenerated ? (
                  <>
                    <MessageSquare className="h-5 w-5" />
                    Questions Generated
                  </>
                ) : (
                  <>
                    <MessageSquare className="h-5 w-5" />
                    Submit Topics
                  </>
                )}
              </Button>
            </motion.div>
          )}
        </AnimatePresence>
      </CardContent>
    </Card>
  );
};

export default TopicBasedInterview;
