import React from "react";
import { Card } from "./ui/card";
import { Badge } from "./ui/badge";
import { ExternalLink } from "lucide-react";
import { Button } from "./ui/button";

const FormattedPost = ({ post }) => {
  // Function to format bold text within a paragraph
  const formatBoldText = (text) => {
    if (!text) return "";

    // Split the text by bold markers
    const parts = text.split(/(\*\*.*?\*\*)/g);

    return parts.map((part, index) => {
      if (part.startsWith("**") && part.endsWith("**")) {
        // Remove the ** markers and wrap in strong tag
        return (
          <strong key={index} className="font-bold">
            {part.slice(2, -2)}
          </strong>
        );
      }
      return <React.Fragment key={index}>{part}</React.Fragment>;
    });
  };

  // Function to format the post content with proper spacing and bold text
  const formatContent = (content) => {
    if (!content) return "";

    // Split content into paragraphs
    const paragraphs = content.split("\n").filter(Boolean);

    return paragraphs.map((paragraph, index) => (
      <p
        key={index}
        className={`${
          index === 0
            ? "text-lg font-semibold mb-4" // Style for hook/first paragraph
            : "mb-3 last:mb-0"
        }`}
      >
        {formatBoldText(paragraph)}
      </p>
    ));
  };

  return (
    <Card className="p-6 mb-6 border-2 hover:border-black transition-all duration-200">
      <div className="space-y-4">
        {/* Title and Content Type */}
        <div className="flex justify-between items-start gap-4">
          <h3 className="font-bold text-xl">{formatBoldText(post.title)}</h3>
          <Badge variant="outline" className="border-2">
            {post.contentType}
          </Badge>
        </div>

        {/* Main content */}
        <div className="mt-4 text-gray-700 whitespace-pre-line">
          {formatContent(post.fullPost)}
        </div>

        {/* Trello link if available */}
        {post.trelloCardUrl && (
          <div className="mt-4 pt-4 border-t">
            <Button
              onClick={() => window.open(post.trelloCardUrl, "_blank")}
              variant="outline"
              className="w-full flex items-center justify-center gap-2 hover:bg-gray-50"
            >
              <ExternalLink className="h-4 w-4" />
              View in Trello
            </Button>
          </div>
        )}

        {/* Error message if post generation failed */}
        {post.error && (
          <div className="mt-4 p-4 bg-red-50 text-red-600 rounded-lg">
            {post.error}
          </div>
        )}
      </div>
    </Card>
  );
};

export default FormattedPost;
