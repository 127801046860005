// FormPersistence.js
import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useToast } from "src/components/ui/use-toast";

export const useFormPersistence = (supabase, initialFormData) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState(initialFormData);
  const { toast } = useToast();
  const navigate = useNavigate();

  // Initialize or restore form data
  useEffect(() => {
    const initializeForm = async () => {
      setIsLoading(true);
      try {
        const formId = searchParams.get("formId");

        if (formId) {
          // Try to fetch existing form data
          const { data, error } = await supabase
            .from("form_submissions")
            .select("*")
            .eq("id", formId)
            .single();

          if (error) throw error;

          if (data) {
            // Restore form data
            setFormData({
              website: data.website || "",
              email: data.email || "",
              industry: data.industry || "",
              usp: data.usp || "",
              icp: data.icp || "",
              linkedinProfile: data.linkedin_profile || "",
              topicsToTalkAbout: data.topics_to_talk_about || "",
              interviewAnswers: data.interview_answers || {},
              trelloBoardId: data.trello_board_id || null,
              trelloTodoListId: data.trello_todo_list_id || null,
              trelloFormListId: data.trello_form_list_id || null,
              // Add other fields as needed
            });
          }
        }
      } catch (error) {
        console.error("Error initializing form:", error);
        toast({
          title: "Error",
          description: "Failed to initialize form. Please try again.",
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    };

    initializeForm();
  }, []);

  // Save form progress
  const saveProgress = async (newData) => {
    const formId = searchParams.get("formId");
    if (!formId) return;

    try {
      const { error } = await supabase
        .from("form_submissions")
        .update({
          website: newData.website,
          email: newData.email,
          industry: newData.industry,
          usp: newData.usp,
          icp: newData.icp,
          linkedin_profile: newData.linkedinProfile,
          topics_to_talk_about: newData.topicsToTalkAbout,
          interview_answers: newData.interviewAnswers,
          trello_board_id: newData.trelloBoardId,
          trello_todo_list_id: newData.trelloTodoListId,
          trello_form_list_id: newData.trelloFormListId,
          // Add other fields as needed
        })
        .eq("id", formId);

      if (error) throw error;

      setFormData(newData);

      toast({
        title: "Progress Saved",
        description: "Your progress has been saved successfully.",
        variant: "success",
      });
    } catch (error) {
      console.error("Error saving progress:", error);
      toast({
        title: "Error",
        description: "Failed to save progress. Please try again.",
        variant: "destructive",
      });
    }
  };

  // Debounced save function
  const debouncedSave = debounce(saveProgress, 1000);

  // Update form data and save progress
  const updateFormData = (newData) => {
    setFormData(newData);
    debouncedSave(newData);
  };

  return {
    formData,
    updateFormData,
    isLoading,
    formId: searchParams.get("formId"),
  };
};

// Utility function for debouncing
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
