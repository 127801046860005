// trelloIntegration.js

import axios from "axios";

const MAX_RETRIES = 3;
const INITIAL_TIMEOUT = 10000; // 10 seconds

export async function createTrelloBoard(formData, organizationId) {
  console.log("Creating Trello board with data:", {
    formData,
    organizationId,
  });
  try {
    const response = await axios.post("/api/create-trello-board", {
      formData,
      organizationId,
    });
    console.log("Trello board created, response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error creating Trello board:", error);
    if (error.response) {
      console.error("Error response from server:", error.response.data);
    }
    throw error;
  }
}

export async function createTrelloCard(cardData, boardId, listName) {
  console.log("Creating Trello card with data:", {
    cardData,
    boardId,
    listName,
  });
  try {
    if (!cardData) {
      throw new Error("Missing required parameters for creating Trello card");
    }
    const response = await axios.post("/api/create-trello-card", {
      cardData,
      boardId,
      listName,
    });
    console.log("Trello card created, response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error in createTrelloCard:", error);
    if (error.response) {
      console.error("Error response from server:", error.response.data);
    }
    throw error;
  }
}

export async function uploadFileToTrello(file, cardId) {
  console.log(`Preparing to upload file: ${file.name} to card: ${cardId}`);
  const formData = new FormData();
  formData.append("file", file);
  formData.append("cardId", cardId);

  try {
    console.log("Sending request to /api/upload-file-to-trello");
    const response = await axios.post("/api/upload-file-to-trello", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("File upload response:", response.data);
    return response.data;
  } catch (error) {
    console.error(`Error uploading file to Trello:`, error);
    if (error.response) {
      console.error("Error response from server:", error.response.data);
    }
    throw error;
  }
}

export async function createTrelloCardsForIdeas(
  selectedIdeas,
  formData,
  boardId
) {
  console.log("Creating Trello cards for ideas:", {
    selectedIdeas,
    formData,
    boardId,
  });
  const cardUrls = [];
  for (const idea of selectedIdeas) {
    try {
      const cardData = {
        name: idea.title,
        desc: `${idea.description}\n\nContent Type: ${idea.contentType}\n\nHook: ${idea.hook}`,
      };
      const cardResponse = await createTrelloCard(cardData, boardId);
      cardUrls.push(cardResponse.shortUrl);
    } catch (error) {
      console.error(`Failed to create card for idea: ${idea.title}`, error);
    }
  }
  return cardUrls;
}

export async function inviteMemberToBoard(boardId) {
  try {
    const response = await axios.post("/api/invite-to-board", {
      boardId,
    });
    console.log("Member invited to board, response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error inviting member to board:", error);
    if (error.response) {
      console.error("Error response from server:", error.response.data);
    }
    throw error;
  }
}

export async function createTrelloCardWithRetry(
  cardData,
  boardId,
  listName = "To Do",
  retryCount = 0
) {
  try {
    return await createTrelloCard(cardData, boardId, listName);
  } catch (error) {
    if (retryCount < MAX_RETRIES) {
      console.log(`Retrying create Trello card (attempt ${retryCount + 2})...`);
      await new Promise((resolve) =>
        setTimeout(resolve, INITIAL_TIMEOUT * Math.pow(2, retryCount))
      );
      return createTrelloCardWithRetry(
        cardData,
        boardId,
        listName,
        retryCount + 1
      );
    } else {
      throw error;
    }
  }
}
