import React, { useEffect } from "react";
import { useFormPersistence } from "src/hooks/FormPersistence";
import { createClient } from "@supabase/supabase-js";
import { useToast } from "./ui/use-toast";
import { useSearchParams } from "react-router-dom";
import { initialFormState } from "src/lib/formStates";

// Initialize Supabase client
const supabase = createClient(
  "https://jhknbuxutiqdvlxnvawv.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Impoa25idXh1dGlxZHZseG52YXd2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjcyOTgxMDYsImV4cCI6MjA0Mjg3NDEwNn0.l3K1xIyN2uZHUrXvjSGr_UXW2s2n9dQvXz8kB_x4K1E"
);

const PersistentForm = ({ children, onFormDataChange, currentStep }) => {
  const { toast } = useToast();
  const [searchParams] = useSearchParams();

  const { formData, updateFormData, isLoading, formId } = useFormPersistence(
    supabase,
    initialFormState
  );

  useEffect(() => {
    if (!isLoading && formData) {
      onFormDataChange(formData);
    }
  }, [formData, isLoading, onFormDataChange]);

  const handleFormUpdate = async (newData) => {
    if (!formId) return;

    try {
      const updatedData = {
        ...formData, // Base data
        ...newData, // New changes
        modified_at: new Date().toISOString(),
      };

      // Update form data in Supabase and local state
      await updateFormData(updatedData);

      // Notify parent component with complete updated data
      onFormDataChange(updatedData);
    } catch (error) {
      console.error("Error updating form:", error);
      toast({
        title: "Error",
        description: "Failed to save your progress. Please try again.",
        variant: "destructive",
      });
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-black"></div>
      </div>
    );
  }

  // Clone children and inject necessary props
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        formData,
        onFormUpdate: handleFormUpdate,
        formId,
        key: formId, // Ensure re-render on formId change
      });
    }
    return child;
  });

  return <div className="relative">{childrenWithProps}</div>;
};

export default PersistentForm;
